import React from 'react'

export const ShowOrderModalButton = ({ id, onClick }) => {
  return (
    <button
      // key={`button-table-${id}`}
      type="button"
      className="btn btn-default"
      style={{ marginLeft: '10px' }}
    >
      <i className="entypo-cog" onClick={() => onClick && onClick()}></i>
    </button>
  )
}
