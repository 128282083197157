import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Table from "../../components/table/table";
import { USER_ROLES } from "../../core/constants/roles";
import { formatDate } from "../../core/utils/date";
import { changeStatus, getOrders } from "./../../resources/services";
import { DetailEnviadosPedidosModal } from "./components/detail-enviados-pedidos-modal";

class Enviados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadError: false,
      data: [],
      dataOrders: [],
      showModal: false,
      currentRow: {},
      name: "",
      price: "",
      showPriceError: false,
    };

    getOrders(null, 7).then((result) => {
      if (result.response) {
        this.setState({
          data: result.orders,
        });
      } else {
        toast("Se ha producido un error al realizar la consulta", {
          type: toast.TYPE.ERROR,
        });
      }
    });

    this.columns = [
      {
        name: "Nº Lote",
        selector: "ID_SERIES",
        sortable: true,
      },
      {
        name: "Clinica",
        selector: "CLINIC_NAME",
        sortable: true,
      },
      {
        name: "Nombre",
        selector: "NOMBRE_PACIENTE",
        sortable: true,
      },
      {
        name: "Apellidos",
        selector: "APELLIDOS_PACIENTE",
        sortable: true,
      },
      {
        name: "Fecha creación",
        cell: (row) => {
          return formatDate(row.FECHA_CREACION);
        },
        sortable: true,
      },
      {
        name: "Estado",
        selector: "STATUS_NAME",
        sortable: true,
      },
      {
        name: "Fecha envío/entrega",
        selector: "FECHA_ENVIO",
        cell: (row) => {
          return row.FECHA_ENVIO ? formatDate(row.FECHA_ENVIO) : "";
        },
        sortable: true,
      },
      {
        name: "Etiqueta",
        cell: (row) => {
          return [
            <button
              className="btn btn-default"
              style={{ marginLeft: 15 }}
              onClick={() =>
                window.confirm(
                  `Seguro que deseas rescatar este pedido? Volverá al estado finalizado`
                ) && this.setStatus(row.UKEY)
              }
            >
              <i className="entypo-export"></i>
            </button>,
          ];
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    //only ROLES different different CLIENT ADMIN AND DESIGNER can see the deliveryDate column
    if (
      this.props.app.idRol === USER_ROLES.ADMIN ||
      this.props.app.idRol === USER_ROLES.DESIGNER
    ) {
      this.columns.push({
        name: "acciones",
        cell: (row) => {
          return (
            <button
              className="btn btn-default"
              style={{ marginLeft: 15 }}
              onClick={() => this.openModal(row.UKEY)}
              key={`${row.PUBLIC_ID}_modal}`}
            >
              <i className="entypo-eye"></i>
            </button>
          );
        },
      });
    }
    this.openModal = this.openModal.bind(this);
  }

  openModal = (ukey) => {
    const orders = this.state.data?.find((delivery) => delivery.UKEY === ukey);
    console.log(orders);
    this.setState({ dataOrders: orders, showModal: true });
  };

  setStatus = (id) => {
    changeStatus({ id, status: 4 }).then((response) => {
      if (response.response === 1 && response.update === 1) {
        const data = this.state.data.filter((v) => v.UKEY !== id);
        let newState = { data };
        this.setState({ ...newState, showModal1: false });
        toast("El pedido ha vuelto al estado finalizado", {
          type: toast.TYPE.SUCCESS,
        });
      } else {
        toast("No se ha podido actualizar el status del pedido", {
          type: toast.TYPE.ERROR,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[{ title: "Pedidos enviados", url: "enviados" }]}
        />
        <Table
          keyFilter={"NOMBRE_PACIENTE"}
          data={this.state.data}
          columns={this.columns}
          title="Enviados"
          pagination
          defaultSortField="ID_SERIES"
          defaultSortAsc={false}
        />
        <DetailEnviadosPedidosModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          data={this.state.dataOrders}
        ></DetailEnviadosPedidosModal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps)(Enviados);
