import React from "react";

export const InvoiceModal = ({ show, onClose, data }) => {
  const handleOnClose = () => {
    onClose && onClose();
  }
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <object
              data={data}
              type="application/pdf"
              style={{ width: "100%", height: "calc(100vh - 270px)" }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
