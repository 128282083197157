import externa_larga_retropie from "./../img/shadows-png/CONTENCION/externa_larga_retropie.png";
import intern_larga_retropie from "./../img/shadows-png/CONTENCION/intern_larga_retropie.png";
import lateral_corta from "./../img/shadows-png/CONTENCION/lateral_corta.png";
import medial_corta from "./../img/shadows-png/CONTENCION/medial_corta.png";
import mediopie_alza from "./../img/shadows-png/CONTENCION/mediopie.png";
import retropie_alza from "./../img/shadows-png/CONTENCION/retropie.png";

export const data_contencion = {
  title: "contención/estabilización",
  dimension: 12,
  components: [
    {
      properties: {
        dimensions: {
          label: 1,
          element: 11,
        },
        label: "",
        type: "checkbox",
        group: [
          {
            type: "checkBox",
            label: "RETROPIE",
            value: "CR",
            name: "retropie_contencion",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "MEDIOPIE",
            value: "CM",
            name: "mediopie_contencion",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "INTERNA CORTA",
            value: "CIC",
            name: "medial_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "INTERNA LARGA",
            value: "CIL",
            name: "medial_larga",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "EXTERNA CORTA",
            value: "CEC",
            name: "lateral_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "EXTERNA LARGA",
            value: "CEL",
            name: "lateral_larga",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Interna Completa",
            value: "CICO",
            name: "interna_completa",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "externa completa",
            value: "CECO",
            name: "externa_completa",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Interna larga + retropié",
            value: "CILR",
            name: "intern_larga_retropie",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Externa larga + retropié",
            value: "CELR",
            name: "extern_larga_retropie",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
        ],
        shadows: [
          {
            value: "medial_corta",
            png: medial_corta,
            styles: {
              top: 282,
              width: 65,
              left: 94,
            },
          },
          {
            value: "intern_larga_retropie",
            png: intern_larga_retropie,
            styles: {
              top: 153,
              width: 145,
              left: 26,
            },
          },
          {
            value: "lateral_corta",
            png: lateral_corta,
            styles: {
              top: 279,
              width: 75,
              left: 29,
            },
          },
          {
            value: "extern_larga_retropie",
            png: externa_larga_retropie,
            styles: {
              top: 230,
              width: 130,
              left: 26,
            },
          },
          {
            value: "mediopie_contencion",
            png: mediopie_alza,
            styles: {
              top: 176,
              width: 149,
              left: 16,
            },
          },
          {
            value: "retropie_contencion",
            png: retropie_alza,
            styles: {
              top: 285,
              width: 125,
              left: 32,
            },
          },
        ],
      },
    },
  ],
};

export const data_alza = {
  title: "Alza",
  dimension: 12,
  components: [
    {
      properties: {
        dimensions: {
          label: 1,
          element: 11,
        },
        label: "",
        type: "checkbox",
        group: [
          {
            type: "checkBox",
            label: "Larga",
            value: "AL",
            name: "alza_larga",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Corta",
            value: "AC",
            name: "alza_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "number",
            label: "ALTURA alza (mm)",
            name: "altura_alza",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Alza completa standard",
            value: "CACS",
            name: "alza_completa_standard",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "number",
            label: "Alza completa standard (mm)",
            name: "altura_alza_completa_standard",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
        ],
        shadows: [
          {
            value: "alza_larga",
            png: mediopie_alza,
            styles: {
              top: 176,
              width: 149,
              left: 16,
            },
          },
          {
            value: "alza_corta",
            png: retropie_alza,
            styles: {
              top: 285,
              width: 125,
              left: 32,
            },
          },
        ],
      },
    },
  ],
};
