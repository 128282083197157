import React from "react";

export const FavoriteModal = ({ show, onClose, onSave, data }) => {
  const [favoriteText, setFavoriteText] = React.useState("");
  const handleOnSave = () => {
    onSave && onSave(favoriteText);
  };
  const handleOnClose = () => {
    onClose && onClose();
  }
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => handleOnClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Añadir a favorito</h4>
          </div>
          <div className="modal-body">
            <p>
              A continuación se guardará la configuración del pedido como
              favorito, podrá usar esta configuración para crear tantos pedidos
              como quiera.
            </p>
            <p>
              <textarea
                style={{ width: "100%", height: "100px" }}
                placeholder="Por favor escriba un nombre"
                maxLength="100"
                onChange={(e) => setFavoriteText(e.target.value)}
                value={favoriteText}
              ></textarea>
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              onClick={() => handleOnSave()}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
