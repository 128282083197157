import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import Table from '../../components/table/table'
import { deleteCustomer, getCustomers } from './../../resources/services'
import { getCustomerColumns } from './utils/columns'

class Customers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }

    getCustomers().then((result) => {
      if (result.response) {
        this.setState({
          data: result.customers
        })
      } else {
        toast('Se ha producido un error al realizar la consulta', {
          type: toast.TYPE.ERROR
        })
      }
    })

    this.deleteCustomer = this.deleteCustomer.bind(this)

    this.columns = getCustomerColumns(this.props.app.idRol, (row) =>
      this.deleteCustomer(row)
    )
  }

  deleteCustomer(json) {
    window.confirm(
      `Estás seguro de que deseas eliminar el usuario ${json.NAME}?`
    ) &&
      deleteCustomer({ u: json.UKEY }).then((result) => {
        if (result.response && result.deleted > 0) {
          let { data } = this.state
          data = data.filter((v) => v.UKEY !== json.UKEY)
          this.setState({ data })
          toast('El cliente se ha borrado con éxito', {
            type: toast.TYPE.SUCCESS
          })
        } else {
          toast('Se ha producido un error al realizar la consulta', {
            type: toast.TYPE.ERROR
          })
        }
      })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb section={[{ title: 'Clientes', url: 'clientes' }]} />
        <div className="text-right">
          {this.props.app.idRol !== '99' ? null : (
            <Link to="/cliente/" className="btn btn-primary">
              <i className="entypo-plus"></i> Añadir cliente
            </Link>
          )}
        </div>
        <Table
          keyFilter={'EMAIL'}
          data={this.state.data}
          columns={this.columns}
          title="Clientes"
          pagination={true}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(Customers)
