import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { environment as ENV } from './../../deploy/environment';

// Actions
import { updateAppAction } from './../../redux/actions/app';

//Images
import logo from './../../assets/images/thumb-1.jpg';
import flagES from './../../assets/images/flags/flag-es.png';

class Header extends Component {

  constructor(props){
    super(props);
    this.onLogout = this.onLogout.bind(this);
    this.pathFile = ENV.rootFiles + '/customer-attachment/' + this.props.app.idUser + '/profile/';
  }

  onLogout(){
    window.localStorage.removeItem('ukey');
    this.props.updateApp({
      logged: false,
      idRol: 0
    });
  }

  render() {
    const logoImg = this.props.app.idRol === "1" && this.props.app.logo !== '' ? this.pathFile + this.props.app.logo : logo;
    return (
        <div className="row">
        <div className="col-md-6 col-sm-8 clearfix">
          <ul className="user-info pull-left pull-none-xsm">
            <li className="profile-info dropdown">
              {
                this.props.app.idRol === "2"
                  ? <div><img src={logoImg} alt="logo" className="img-circle" style={{width: '50px', height: '50px'}} />
                  {this.props.app.name}</div>
                  : <Link to={`/${this.props.app.idRol === "1" ? 'cliente' : 'usuario'}/${this.props.app.idUser}`} className="dropdown-toggle"> 
                      <img src={logoImg} alt="logo" className="img-circle" style={{width: '50px', height: '50px'}} />
                      {this.props.app.name}
                    </Link>
              }
            </li>
          </ul>
        </div>

        <div className="col-md-6 col-sm-4 hidden-xs">

          <ul className="list-inline links-list pull-right">
            <li className="dropdown language-selector">
              Idioma: &nbsp;
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-close-others="true">
                <img src={flagES} width="16" height="16" /> 
              </a>
              <ul className="dropdown-menu pull-right">
                <li className="active"> 
                  <a href="#">
                    <img src={flagES} width="16" height="16" /> <span>Español</span> 
                  </a>
                </li> 
              </ul>
            </li>
            <li className="sep" />
            <li>
              <a href="#" onClick={this.onLogout}>
  					    Log Out <i className="entypo-logout right"></i>
  				    </a>
            </li>
          </ul>

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = {
  updateApp: updateAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
