import propTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import "react-select";

import Select from 'react-select';

// Actions
import { updateOrderAction } from '../../redux/actions/orders';
import { MenuList } from './menu-list';

class Selector extends Component {

  handleChange = (selectedOption) => {

  
    if(!this.props.notSaveInOrder){
        this.props.updateOrder({
            [this.props.name]: selectedOption?.value
        });
    }

    selectedOption && (selectedOption.name = this.props.name);
    if(this.props && this.props.onChange){
        this.props.onChange(selectedOption);
    }
  }
  
  render() {
    const { options, placeholder, dimensions, virtualized, addStyle } = this.props;
    let selected = this.props.options.filter(
        v => v.value === this.props.orders[this.props.name]
    );

    // Uso del select fuera de orders, añadimos condicion
    if(!selected.length){
        selected = this.props.options.filter(
            v => v.value === this.props.value
        );
    }
    
    selected = selected.length === 0 ? '' : selected[0];

    return (
        <Fragment>
            {
                !this.props.hideLabel
                ?
                    <label 
                        htmlFor={this.props.name} 
                        style={addStyle ? addStyle : null}
                        className={`col-sm-${dimensions.label} text-right ${this.props.clear && 'clearfix'} col-xs-12 control-label label-select bold`}>
                            {this.props.label}:
                    </label>
                : null

            }
            <div 
                className={`col-sm-${dimensions.element ? dimensions.element : dimensions.component} col-xs-12`}
                style={{...this.props.style}}
            >
                <Select
                    onChange={this.handleChange}
                    options={options}
                    placeholder={placeholder}
                    value={selected}
                    isClearable={this.props.unselectable}
                    components={virtualized ? { MenuList: MenuList }: {}} 
                    isDisabled={this.props.isDisabled}
                />
            </div>
        </Fragment>
    );
  }
}

Selector.propTypes = {
    options: propTypes.array,
    id: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    dimensions: propTypes.object,
    placeholder: propTypes.string,
    value: propTypes.string,
    isDisabled: propTypes.bool,
    unselectable: propTypes.bool,
    onChange: propTypes.func,
};
  
Selector.defaultProps = {
    options: [
      { value: 'valor', label: 'Valor' },
    ],
    id: '',
    label: 'Label',
    dimensions: {
        label: 2,
        element: 3,
    },
    placeholder: 'Selecciona...',
    value: null,
    unselectable: false,
    isDisabled: false,
};

const mapStateToProps = state => ({
    orders: state.orders,
  });
const mapDispatchToProps = {
    updateOrder: updateOrderAction,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Selector);
