export const normalizeString = (str) => {
  return str && str
    .toLowerCase()
    .replace(/[ÀÁÂÃÄÅ]/g, "A")
    .replace(/[àáâãäå]/g, "a")
    .replace(/[ÈÉÊË]/g, "E")
    .replace(/[èéêë]/g, "e")
    .replace(/[ÌÍÎÏ]/g, "I")
    .replace(/[ìíîï]/g, "i")
    .replace(/[ÒÓÔÕÖØ]/g, "O")
    .replace(/[òóôõöø]/g, "o")
    .replace(/[ÙÚÛÜ]/g, "U")
    .replace(/[ùúûü]/g, "u")
    .replace(/[Ç]/g, "C")
    .replace(/[ç]/g, "c") || str;
};
