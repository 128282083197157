import React from "react";
import { DetailOrderButton } from "./detail-order-button";
import { DownloadOrderButton } from "./download-order-button";

export const DetailEnviadosPedidosModal = (props) => {
  return (
    <div
      className={`modal fade ${props.show ? "in" : ""}`}
      style={{ display: props.show ? "block" : "none" }}
    >
      <div className="modal-dialog" style={{ width: "40%" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Pedidos asociados al envio</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.onHide()}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div className="fifty">Ver pedido:</div>
              <DetailOrderButton ukey={props.data.UKEY}></DetailOrderButton>
            </div>
            <div>
              <div className="fifty">Descargar etiqueta: </div>
              <DownloadOrderButton
                ukey={props.data.UKEY}
                idSeries={props.data.ID_SERIES}
                type="etiqueta"
              />
            </div>
            <div>
              <div className="fifty">Descargar etiqueta de envio: </div>
              <DownloadOrderButton
                ukey={props.ukey}
                idSeries={props.data.ID_SERIES}
                type="etiqueta"
                sendLabel={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
