import React from 'react'
import { Link } from 'react-router-dom'

export const getPodologistColumn = (idRol, onDelete) => {
  const imageStyle = {
    height: '50px',
    width: '30px',
    padding: '12px 0px'
  }
  let columns = [
    {
      name: 'Nombre del Podólogo',
      selector: 'NAME',
      sortable: true
    },
    {
      name: 'Clinica',
      selector: 'CLINIC_NAME',
      sortable: true
    },
    {
      cell: (row) => {
        return [
          <Link to={`/podologo/${row.UKEY}`} key={`table-link-${row.UKEY}`}>
            <button
              type="button"
              className="btn btn-default"
              style={{ marginRight: '10px' }}
            >
              <i className={`entypo-${idRol === '4' ? 'eye' : 'pencil'}`}></i>
            </button>
          </Link>,
          idRol !== '99' ? null : (
            <button
              type="button"
              className="btn btn-red"
              key={`table-button-${row.UKEY}`}
              onClick={() => onDelete && onDelete(row)}
            >
              <i className="entypo-trash"></i>
            </button>
          )
        ]
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]

  if (idRol !== '99') {
    columns = columns.filter((v) => v.selector !== 'DISCOUNT')
  }

  return columns
}
