// ANTEPIE
import alargoMorton from "./../img/shadows-png/ANTEPIE/alargo_morton.png";
import carril from "./../img/shadows-png/ANTEPIE/carril.png";
import colaRaton from "./../img/shadows-png/ANTEPIE/cola_raton.png";
import pronadoraAntepie from "./../img/shadows-png/ANTEPIE/cuna_pronadora_antepie.png";
import supinadoraAntepie from "./../img/shadows-png/ANTEPIE/cuna_supinadora_antepie.png";
import cutout from "./../img/shadows-png/ANTEPIE/cut_out_1.png";

export const data_antepie = {
  title: "Antepié",
  dimension: 12,
  components: [
    {
      properties: {
        required: true,
        dimensions: {
          label: 1,
          element: 12,
        },
        label: "",
        type: "checkbox",
        group: [
          {
            title: "Cuña",
          },
          {
            type: "checkBox",
            label: "CUÑA SUPINADORA ANTEPIE",
            value: "ACS",
            name: "cuña_supinadora_antepie",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "CUÑA PRONADORA ANTEPIE",
            value: "ACP",
            name: "cuña_pronadora_antepie",
            feet: {
              name: "Pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "select",
            label: "Cuña grosor",
            hideLabel: true,
            unselectable: true,
            placeholder: "Elige grosor...",
            dimensions: {
              label: 1,
              component: 6,
            },
            addStyle: {
              paddingLeft: "2rem",
            },
            name: "cuña_grosor",
            options: [
              {
                label: "Sin grosor",
                value: "",
              },
              {
                label: "1 mm",
                value: "ACG1",
              },
              {
                label: "2 mm",
                value: "ACG2",
              },
              {
                label: "3 mm",
                value: "ACG3",
              },
              {
                label: "4 mm",
                value: "ACG4",
              },
              {
                label: "5 mm",
                value: "ACG5",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "únicamente zona metatarsal",
            value: "UZM",
            name: "unicamente_zona_metatarsal",
            addStyle: {
              paddingLeft: "2rem",
            },
          },
          {
            title: "Descargas",
          },
          {
            type: "checkbox",
            label: "Cut out",
            value: "cut_out",
            name: "cut_out",
            placeholder: "Elige cut out...",
            hideLabel: true,
            dimensions: {
              label: 1,
              component: 6,
            },
            multiple: true,
            options: [
              {
                label: "1",
                value: "ADCO1",
              },
              {
                label: "2",
                value: "ADCO2",
              },
              {
                label: "3",
                value: "ADCO3",
              },
              {
                label: "4",
                value: "ADCO4",
              },
              {
                label: "5",
                value: "ADCO5",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Cut out relleno de poron",
            value: "ADCORP",
            name: "cut_out_relleno_poron",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            addStyle: {
              paddingLeft: "2rem",
            },
          },
          {
            type: "select",
            label: "Carril",
            hideLabel: true,
            unselectable: true,
            placeholder: "Tipo de carril",
            dimensions: {
              label: 1,
              component: 6,
            },
            name: "carril",
            options: [
              {
                label: "Sin carril",
                value: "",
              },
              {
                label: "1",
                value: "ADCA1",
              },
              {
                label: "2-3",
                value: "ADC23",
              },
              {
                label: "3-4",
                value: "ADC34",
              },
              {
                label: "5",
                value: "ADCA5",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "Carril relleno de poron",
            value: "ADCRP",
            name: "carril_relleno_poron",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            addStyle: {
              paddingLeft: "2rem",
            },
          },
          {
            type: "checkBox",
            label: "únicamente zona metatarsal",
            value: "CUZM",
            name: "carril_unicamente_zona_metatarsal",
            addStyle: {
              paddingLeft: "2rem",
            },
          },
          {
            title: "Otros elementos",
          },
          {
            type: "checkBox",
            label: "Alargo de mortón",
            value: "AOAM",
            name: "alargo_de_morton",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "alargo de EVA",
            value: "AOEVA",
            name: "alaro_de_eva",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Cola de ratón",
            value: "AOCR",
            name: "cola_de_raton",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Gait plate",
            value: "AOGP",
            name: "gait_plate",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label:
              "Cuña cinetica de Dananberg (carril1+medial heel grosor 3mm+estabilizador interno)",
            value: "AOCD",
            name: "cuña_dananberg",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
        ],
        shadows: [
          {
            value: "cut_out",
            png: cutout,
            styles: {
              top: -325,
              width: 520,
              left: 17,
            },
          },
          {
            value: "cuña_supinadora_antepie",
            png: supinadoraAntepie,
            styles: {
              top: -268,
              left: 22,
              width: 550,
            },
          },
          {
            value: "cuña_pronadora_antepie",
            png: pronadoraAntepie,
            styles: {
              top: -367,
              left: -12,
              width: 550,
            },
          },
          {
            value: "cola_de_raton",
            png: colaRaton,
            styles: {
              top: -261,
              width: 520,
              left: -6,
            },
          },
          {
            value: "carril",
            png: carril,
            styles: {
              top: -628,
              left: 26,
              width: 550,
            },
          },
          {
            value: "alargo_de_morton",
            png: alargoMorton,
            styles: {
              top: -290,
              left: 45,
              width: 550,
            },
          },
        ],
      },
    },
  ],
};
