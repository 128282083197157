import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { changeMoldeStatus } from '../../../resources/services'

export const ChangeMoldeStatusCheckbox = ({ data, onChange }) => {
  const [checked, setChecked] = useState(data.MOLDE === '1')
  const handleOnChange = (val) => {
    const newVal = val.target.checked
    const dataToSend = {
      id: data.UKEY,
      molde: newVal ? '1' : '0',
      UKEY: localStorage.getItem('ukey')
    }

    changeMoldeStatus(dataToSend).then((result) => {
      if (result.response !== 0 && result.update !== 0) {
        toast(newVal ? 'Molde realizado' : 'Se ha quitado la selección del molde realizado ', {
          type: toast.TYPE.SUCCESS
        })
        setChecked(newVal)
        onChange && onChange()
      } else {
        toast('No se ha podido cambiar el estado del molde', {
          type: toast.TYPE.ERROR
        })
      }
    })
  }

  useEffect(() => {
    setChecked(data.MOLDE === '1')
  }, [data])

  return (
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          checked={checked}
          style={{ cursor: 'pointer' }}
          onChange={handleOnChange}
        />
      </label>
    </div>
  )
}
