export const ORDERS_COMBINATIONS = {
  cuña_dananberg: {
    carril: "ADCA1",
    carril_pie: "Bi",
    cuña_interna_corta: "RCIC",
    cuña_interna_corta_pie: "Bi",
    grosor_retropie_cuña: "RCG3",
    grosor_retropie_cuña_pie: "Bi",
    mediopie_contencion: "CM",
    medial_corta: "Bi",
  },
  descarga_de_espolon: {
    poron_talon: "RDPT",
    alza_corta: "AC",
    alza_corta_pie: "Bi",
    altura_alza: "2",
    altura_alza_pie: "Bi",
  },
  balances_inversion_pie: {
    intern_larga_retropie: "CILR",
    intern_larga_retropie_pie: "Bi",
  },
  balances_eversion_pie: {
    extern_larga_retropie: "CELR",
    extern_larga_retropie_pie: "Bi",
  },
  cuña_pronadora_intrinsica_pie: {
    lateral_larga: "CEL",
    lateral_larga_pie: "Bi",
  },
  cuña_supinadora_intrinsica_pie: {
    medial_larga: "CIL",
    medial_larga_pie: "Bi",
  },
  cuña_interna_corta_pie: {
    medial_corta: "CIC",
    medial_corta_pie: "Bi",
  },
  cuña_externa_corta_pie: {
    lateral_corta: "CEC",
    lateral_corta_pie: "Bi",
  },
  cuña_pronadora_extrinseca_corta: {
    lateral_larga: "CEL",
    lateral_larga_pie: "Bi",
  },
  cuña_supinadora_extrinseca_corta: {
    medial_larga: "CIL",
    medial_larga_pie: "Bi",
  },
  cuña_supinadora_extrinsica_mediopie: {
    medial_larga: "CIL",
    medial_larga_pie: "Bi",
  },
  cuña_pronadora_extrinsica_mediopie: {
    lateral_larga: "CEL",
    lateral_larga_pie: "Bi",
  },
};

export const getCombinationOrderObject = (data) => {
  return ORDERS_COMBINATIONS[data] ?? {};
};

export const getCombinationOrderKeys = (data) => {
  const combination = getCombinationOrderObject(data);
  return Object.keys(combination) ?? [];
};
