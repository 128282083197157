import cazoleta from "./../img/shadows-png/RETROPIE/cazoleta.png";
import cuna_ext_corta from "./../img/shadows-png/RETROPIE/cuna_ext_corta.png";
import cuna_int_corta from "./../img/shadows-png/RETROPIE/cuna_int_corta.png";
import descarga_de_espolon from "./../img/shadows-png/RETROPIE/descarga_de_espolon.png";
import poron_talon from "./../img/shadows-png/RETROPIE/poron_talon.png";

export const data_retropie = {
  title: "Retropié",
  dimension: 12,
  components: [
    {
      properties: {
        dimensions: {
          label: 1,
          element: 11,
        },
        label: "",
        type: "checkbox",
        group: [
          {
            title: "Cuña",
          },
          {
            type: "checkBox",
            label: "cuña pronadora intrínseca / LATERAL HEEL",
            value: "RCEC",
            name: "cuña_externa_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "cuña supinadora intrínseca / Medial HEEL",
            value: "RCIC",
            name: "cuña_interna_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Cuña pronadora extrínseca corta",
            value: "RCPEC",
            name: "cuña_pronadora_extrinseca_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Cuña supinadora extrínseca corta",
            value: "RCSEC",
            name: "cuña_supinadora_extrinseca_corta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "select",
            label: "Grosor cuña",
            value: "grosor_retropie_cuña",
            name: "grosor_retropie_cuña",
            hideLabel: true,
            unselectable: true,
            placeholder: "Grosor...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "Sin cuña",
                value: "",
              },
              {
                label: "1 mm",
                value: "RCG1",
              },
              {
                label: "2 mm",
                value: "RCG2",
              },
              {
                label: "3 mm",
                value: "RCG3",
              },
              {
                label: "4 mm",
                value: "RCG4",
              },
              {
                label: "5 mm",
                value: "RCG5",
              },
              {
                label: "6 mm",
                value: "RCG6",
              },
              {
                label: "7 mm",
                value: "RCG7",
              },
              {
                label: "8 mm",
                value: "RCG8",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            title: "Descargas",
          },
          {
            type: "checkBox",
            label:
              "DESCARGA DE ESPOLóN  (fenestracion rellena de poron + poron talon + alza 0,2 bilateral)",
            value: "RDE",
            name: "descarga_de_espolon",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "PORON TALóN",
            value: "RDPT",
            name: "poron_talon",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "number",
            label: "ALTURA CAZOLETA (mm)",
            name: "altura_cazoleta",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
        ],
        shadows: [
          {
            value: "descarga_de_espolon",
            png: descarga_de_espolon,
            styles: {
              top: 279,
              width: 69,
              left: 62,
            },
          },
          {
            value: "poron_talon",
            png: poron_talon,
            styles: {
              top: 265,
              width: 129,
              left: 28,
            },
          },
          {
            value: "altura_cazoleta",
            png: cazoleta,
            styles: {
              top: 254,
              width: 119,
              left: 37,
            },
          },
          {
            value: "cuña_externa_corta",
            png: cuna_ext_corta,
            styles: {
              top: 296,
              width: 69,
              left: 35,
            },
          },
          {
            value: "cuña_interna_corta",
            png: cuna_int_corta,
            styles: {
              top: 268,
              width: 69,
              left: 90,
            },
          },
        ],
      },
    },
  ],
};
