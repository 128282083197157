import React from 'react'

export const CardArregloComponent = ({title, children, className}) => {
    return <div className={className}>
    <div className="panel panel-primary" data-collapsed="0">
      <div className="panel-heading">
        <div className="panel-title">{title}</div>
        <div className="panel-options"></div>
      </div>
      <div className="panel-body">
        <div className="row">
          <div style={{'minHeight': '112px', width: '100%'}}>
            <div className="col-xs-12 col-sm-12">
                {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}