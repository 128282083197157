import React, { Component } from 'react';
import propTypes from 'prop-types';

// styles
import './title.css';

class Title extends Component {
  
  render() {
    const Tag = 'h' + this.props.size;
    return (
        <div style={{padding: '10px'}}>
            <Tag className="control-label title-separator">{this.props.title}</Tag>
        </div>
    );
  }
}

Title.propTypes = {
    label: propTypes.string,
    size: propTypes.number,
};
  
Title.defaultProps = {
    label: 'Title',
    size: 2,
};

export default Title;
