import propTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import svgLoading from '../../assets/images/loading.svg'

// Actions
import { updateOrderAction } from '../../redux/actions/orders'

// styles
import './input.css'

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.type === 'checkbox') {
      const checked =
        nextProps.type === 'checkbox' &&
        nextProps.orders[nextProps.name] === nextProps.value
          ? true
          : false
      if (checked !== this.state.checked) {
        this.setState({ checked })
      }
    }
    return true
  }

  onClick = (e) => {
    if (this.props.type === 'checkbox') {
      this.setState({ checked: e.target.checked })
    }
  }

  updateValue = (e) => {
    const { type, value } = this.props
    const thisValue =
      type === 'checkbox' && e.target.checked
        ? value
        : type === 'color'
        ? value
        : e.target.value
    if (this.props.isolateComponent) return false
    this.props.updateOrder({
      [this.props.name]: thisValue
    })
  }

  onBlur = (e) => {
    this.updateValue(e)
  }

  onKeyUp = (e) => {
    if (this.props.onEnter) {
      if (e.key === 'Enter') {
        this.props.onEnter(e.currentTarget?.value)
      }
    }
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value)
    if (this.props.type === 'color') {
      console.log(e.target.value)
    }
  }

  render() {
    const {
      label,
      hideLabel,
      dimensions,
      value,
      type,
      name,
      id,
      placeholder,
      orders,
      required,
      clear,
      defaultValue,
      disabled
    } = this.props
    const requiredClass = required ? 'required' : ''
    const inputClass = type !== 'checkbox' ? 'form-control' : ''

    return (
      <Fragment>
        {!hideLabel ? (
          <div
            className={`col-sm-${dimensions.label} text-right col-xs-4
                        ${clear && 'clearfix'}
                    `}
          >
            <label htmlFor={value} className='control-label bold'>
              {label}
              {label.trim() === '' ? '' : ':'}
            </label>
          </div>
        ) : null}
        <div className={`col-sm-${dimensions.element} form-group col-xs-8`}>
          <input
            className={inputClass}
            type={type}
            name={name}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={this.onBlur}
            onClick={this.onClick}
            value={value && value}
            defaultValue={defaultValue || orders[name]}
            data-validate={requiredClass}
            data-msg-required='Campo requerido.'
            defaultChecked={this.state.checked}
            onChange={this.onChange}
            onKeyUp={this.onKeyUp}
          />
          {this.props.loading && (
            <img
              className='logo-loading'
              alt='loading'
              src={svgLoading}
              width='40'
              style={{ position: 'absolute', left: '100%', top: '50px' }}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

Input.propTypes = {
  placeholder: propTypes.string,
  name: propTypes.string,
  type: propTypes.string,
  id: propTypes.string,
  label: propTypes.string,
  dimensions: propTypes.object,
  hideLabel: propTypes.bool,
  group: propTypes.array
}

Input.defaultProps = {
  placeholder: 'Escribe un texto',
  name: '',
  type: 'text',
  id: '',
  label: 'Label',
  dimensions: {
    label: 2,
    element: 3
  },
  hideLabel: false,
  group: []
}

const mapStateToProps = (state) => ({
  orders: state.orders
})
const mapDispatchToProps = {
  updateOrder: updateOrderAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Input)
