
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Table from '../../components/table/table';
import { forros, horma_material_base } from '../../data';
import { deleteFavorite, getFavorites } from '../../resources/services';

import { formatTime } from '../../core/utils/date';
import { resetStoreAction, updateOrderAction } from './../../redux/actions/orders';

class Favoritos extends Component {

  constructor(props){
    super(props);
    this.state = {
      loadError: false,
      data:[],
      showModal: false,
      currentRow: {},
      name: '',
      price: '',
      showPriceError: false,
      redirect: false
    };

    let horma = {};
    let m_base = {};
    let frs = {};
    horma_material_base[0].components.forEach(v => {
      horma = { ...horma, [v?.properties?.value]: v?.properties?.label }
    })
    horma_material_base[1].components.forEach(v => {
      m_base = { ...m_base, [v?.properties?.value]: v?.properties?.label }
    })
    forros[0].components.forEach(v => {
      if(v?.properties?.value){
        frs = { ...frs, [v?.properties?.value]: v?.properties?.label }
      } else if(v?.properties?.options) {
        v.properties.options.forEach(o => {
          frs = { ...frs, [o.value]: o.label }
        })
      }
    })

    getFavorites(null,4)
    .then((result) => {
      if(result.response){
        this.setState({
          data: result.favorites
        })
      }else{
        toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
      }
    })

    this.columns = [
      {
        name: 'Nombre',
        selector: 'NAME',
        sortable: true,
      },
      {
        name: 'Horma',
        selector: 'HORMA',
        sortable: true,
        cell: (row) => horma[row.HORMA]
      },
      {
        name: 'Material base',
        selector: 'M_BASE',
        sortable: true,
        cell: (row) => m_base[row.M_BASE]
      },
      {
        name: 'Forro',
        selector: 'FORRO',
        sortable: true,
        cell: (row) => frs[row.FORRO]
      },
      {
        name: 'Precio',
        selector: 'PRICE',
        sortable: true,
        cell: (row) => row.PRICE+' €'
      },
      {
        name: 'Fecha creación',
        selector: 'FECHA_CREACION',
        cell: row => {
          return formatTime(row.FECHA_CREACION)
        },
        sortable: true,
      },
      {
        name: 'Acciones',
        cell: (row) => {
          return [
            <button 
              type="button"
              className="btn btn-default"
              onClick={() => window.confirm(`Deseas crear un nuevo pedido con esta configuración?`) && this.createOrderByFavorite(row.UKEY)}
            ><i className="entypo-docs"></i>
            </button>,
            <button 
              type="button"
              className="btn btn-red"
              style={{marginLeft: '5px;'}}
              onClick={() => this.deleteFavorites(row.UKEY)}
            ><i className="entypo-trash"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];
  }

  deleteFavorites(currentUkey){
    window.confirm(`Estás seguro de que deseas eliminar el favorito?`) && deleteFavorite({u:currentUkey})
      .then((result) => {
        if(result.response && result.deleted != 0){
          let { data } = this.state;
          data = data.filter(v => v.UKEY !== currentUkey);
          this.setState({data});
        }
      })
  }
  createOrderByFavorite(currentUkey){
    let orderData = this.state.data.filter(v => v.UKEY === currentUkey);
    if(orderData.length === 0){
      alert('Ha ocurrido un error al crear el pedido');
      return;
    }
    orderData = JSON.parse(orderData[0].DATA);
    orderData.sended = false;
    orderData.UKEY = undefined;
    delete orderData.UKEY;
    delete orderData.apellidos_del_paciente;
    delete orderData.calzado_del_paciente;
    orderData.id = "";
    delete orderData.id_series;
    delete orderData.nombre_del_paciente;
    delete orderData.sexo_del_paciente;
    orderData.status = 5;
    orderData.step = 0;
    orderData.orderAttachment = [];
    orderData.orderLogoAttachment = [];
    orderData.fromFavorite = true;
    this.props.resetStore();
    this.props.updateOrder(orderData);
    setTimeout(() => {
      this.setState({redirect: true})
    }, 800);
  }

  render() {
    if(this.state.redirect){
      return <Redirect push to="/pedido" />
    }
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Favoritos", url: "favoritos"},
          ]}
        />
        <Table
          keyFilter={'NAME'}
          data={this.state.data}
          columns={this.columns}
          title="Favoritos"
          pagination
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  resetStore: resetStoreAction,
  updateOrder: updateOrderAction,
};

export default connect(null, mapDispatchToProps)(Favoritos);