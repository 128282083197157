import React from "react";
import { toast } from "react-toastify";
import { deleteOrder } from "../../../resources/services";

export const DeleteOrderButton = ({ ukey, onDelete }) => {
  const handleDeleteOrder = () => {
    window.confirm(`Estás seguro de que deseas eliminar el pedido?`) &&
      deleteOrder({ u: ukey }).then((result) => {
        if (result.response && result.deleted != 0) {
          toast("Pedido eliminado correctamente", {
            type: toast.TYPE.SUCCESS,
          });
          onDelete && onDelete({ ukey });
        }
      });
  };

  return (
    <button
      type="button"
      className="btn btn-red"
      key={`button-${ukey}`}
      onClick={() => handleDeleteOrder()}
      style={{ marginLeft: "10px" }}
    >
      <i className="entypo-trash"></i>
    </button>
  );
};
