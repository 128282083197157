import propTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// Components
import ButtonSelector from '../button-selector/button-selector';
import Selector from '../select/select';

// Actions
import { deleteOrderAction, updateOrderAction } from '../../redux/actions/orders';

// styles
import './input-option.css';

class InputOption extends Component {

  constructor(props){
    super(props);
  }

  onChange = (e) => {
    if(this.props.orders[e.target.name] === e.target.value && this.props.type === "checkbox"){
        this.props.deleteOrder(e.target.name);
        return;
    }

    this.props.updateOrder({
        [e.target.name]: e.target.value
    });
  }

  onChangeMultiple = (name, value, parentName) => {
    if(this.props.orders[name] === value){
        let toDelete = [name, parentName+"_pie"];
        const filtered = Object.keys(this.props.orders).filter((v) => v.indexOf(`${parentName}_multiple_`) !== -1);
        if(filtered.length === 1 && filtered[0] === name){
            toDelete = [...toDelete, parentName]
        }
        this.props.deleteOrder(toDelete);
        return;
    }
    this.props.updateOrder({
        [parentName]: 'Si',
        [name]: value
    });
  }

  getInput(value){

    return value.options ? 
        <div style={{display:'inline-block'}}>
            {value.options.map((v,k) => {
                // Multiple const assigned to define more than one option checked
                const name = value.multiple ? `${value.name}_multiple_${k}` : value.name;
                const id = `input_${v.value}_${v.label}`;
                return <Fragment key={`input_option_${value.name}_${k}`}>
                    <label htmlFor={id}>{v.label}</label>
                    <input 
                        key={`input_${v.value}_${v.label}`}
                        id={id}
                        type={value.type}
                        name={name}
                        className={`is-group-multiple`}
                        onChange={() => this.onChangeMultiple(name, v.value, value.name)}
                        value={v.value}
                        checked={this.props.orders[name] === v.value}
                        style={{margin: '0px 15px 0px 5px'}}
                    />
                </Fragment>
            })}
        </div>
    : value.type === "number" || value.type === "text" ?
        <input 
            key={`input_${value.name}`}
            id={value.value}
            type={value.type}
            name={value.name}
            className={`is-group`}
            onBlur={this.onChange}
            defaultValue={this.props.orders[value.name]}
            checked={this.props.orders[value.name] === value.value}
        />
    : <input 
            key={`input_${value.name}`}
            id={value.value}
            type={value.type}
            name={value.name}
            className={`is-group`}
            onChange={this.onChange}
            value={value.value}
            checked={this.props.orders[value.name] === value.value}
        />
  }

  getSelect(value){
    return (
        <div className="selector-content">
            <Selector {...value} />
        </div>
    )
  }

  getButtonSelector(name, values, enabled){
    return (
        <div style={{marginLeft: '15px', display: 'inline-block', marginBottom: '10px'}}>
            <ButtonSelector name={name} values={values} enabled={enabled}/>
        </div>
    )
  }
  
  render() {
    const { label, hideLabel, dimensions, name, clear, group, addStyle, addStyleLabel } = this.props;
    return (
        <Fragment>
            {   
                !hideLabel
                ?
                    <div className={ `col-sm-${dimensions.label} text-right ${clear && 'clearfix'} col-xs-4`} style={addStyle ? addStyle : null}>
                        <label 
                            htmlFor={name} 
                            className={`text-right control-label bold`}
                            style={addStyleLabel ? addStyleLabel : null}>
                                {label}{
                                    label.trim() === '' ? '' : ':'
                                }
                        </label>
                    </div>
                : null

            }
            <div className={`col-sm-${dimensions.element} col-xs-8`}>
            <div style={{marginBottom: '15px'}}>
                {group.map((value, key) => {
                    if(value.separator){
                        return <p key={`p-key-${key}`}></p>
                    }
                    if(value.hr){
                        return <hr key={`hr-key-${key}`}/>
                    }
                    if(value.title){
                        return <h4 key={`h4-key-${key}`}>{value.title}</h4>
                    }
                    return <div key={`${key}_${value.label}`}>
                        <div className={`input-content ${value.goFlex ? 'flexed' : null}`} style={value.addStyle ? value.addStyle : null}>
                            {value.type === 'select' ? this.getSelect(value) : this.getInput(value)}
                            <label htmlFor={value.value} className={`
                                pre-label
                                ${(clear && hideLabel) ? 'clearfix' : ''}
                                ${(value.multiple) ? 'pull-left' : ''}
                            `}>{value.label}</label>
                        </div>
                        {value.feet && value.feet.value
                            ? <div className="switcher-content">{this.getButtonSelector(value.name, value.feet, this.props.orders[value.name])}</div>
                            : null
                        }
                    </div>
                    })}
            </div>
            </div>
        </Fragment>
    );
  }
}

InputOption.propTypes = {
    placeholder: propTypes.string,
    name: propTypes.string,
    type: propTypes.string,
    label: propTypes.string,
    dimensions: propTypes.object,
    hideLabel: propTypes.bool,
    group: propTypes.array,
};
  
InputOption.defaultProps = {
    placeholder: 'Escribe un texto',
    name: '',
    type: 'text',
    label: 'Label',
    dimensions: {
        label: 2,
        element: 3,
    },
    hideLabel: false,
    group: [],
};

const mapStateToProps = state => ({
    orders: state.orders,
  });
const mapDispatchToProps = {
    updateOrder: updateOrderAction,
    deleteOrder: deleteOrderAction,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(InputOption);
