import propTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import './group.css';

class Group extends Component {

  getHeading(data){
    return(
        <div className="panel-heading">
            <div className="panel-title">
                {data.title}
            </div>
            <div className="panel-options">
                {/*<a href="#" data-rel="collapse"><i className="entypo-down-open" /></a>
                 <a href="#" data-rel="reload"><i className="entypo-arrows-ccw" /></a> */}
            </div>
        </div>
    );
  }

  getBody(data){
    return(
        <div className="panel-body">
            <div className="row">
                <Fragment>
                    {data.components.map( (components, key) =>
                        <div key={`components_${key}`} style={components.properties ? components.properties.style : ''}>
                            <components.type
                                {...components.properties}
                            />
                        </div>
                    )}
                </Fragment>
            </div>
        </div>
    );
  }
  
  render() {
    return ( 
        <div className="row page">
            {this.props.data.map( (group, key) =>
            <div 
                className={`col-md-12 col-lg-${group.dimension}`}
                key={`components_main_${key}`}
            >
            <div 
                className="panel panel-primary"
                data-collapsed="0"
                key={`components_contanier_${key}`}
            >
                {this.getHeading(group)}
                {this.getBody(group)}
            </div>
            </div>)}
    </div>)
  }
}

Group.propTypes = {
    label: propTypes.string,
    data: propTypes.array,
};
  
Group.defaultProps = {
    label: 'Label',
    data: [],
};

export default Group;
