import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {Link} from "react-router-dom";
import Input from '../../components/input/input'
import Table from '../../components/table/table'
import { environment } from '../../deploy/environment'
import { getInvoices, generateInvoiceExport } from '../../resources/services'

import { FORMAT_DATE } from '../../core/constants/constants'
import { formatDate, formatServerDate } from '../../core/utils/date'
import { withQueryParamsHandler } from '../../hook/useQueryParams.hook'

import { InvoiceModal } from './modals/invoiceModal';
import { ReportModal } from './modals/reportModal';
import './facturas.css'

class Facturas extends Component {
  constructor(props) {
    super(props)
    const params = this.props.queryParamsHandler.searchObj
    const date = params?.date ? params.date : null

    this.state = {
      loadError: false,
      data: [],
      dataOrders: [],
      showModal: false,
      currentRow: {},
      name: '',
      price: '',
      showPriceError: false,
      loading: false,
      date: date,
      showInvoiceModal: false,
      invoiceSelected: null,
      showReportModal: false,
    }

    this.handleOnCloseInvoiceModal = this.handleOnCloseInvoiceModal.bind(this)
    this.handleOnCloseReportModal = this.handleOnCloseReportModal.bind(this)
    this.handleOnGenerateReport = this.handleOnGenerateReport.bind(this)

    this.getInvoicesByDate(date)

    this.invoiceColumns = [
      {
        name: 'Nº Factura',
        selector: 'INVOICE_NUMBER',
        cell: (row) => {
          return `${row.PREFIX}-${row.INVOICE_NUMBER}`
        }
      },
      {
        name: 'Fecha de facturación',
        selector: 'INVOICE_DATE',
        cell: (row) => {
          return formatDate(row.INVOICE_DATE)
        },
        sortable: true
      },
      {
        name: 'Ver',
        cell: (row) => {
          const fileName = row.PREFIX === 'FS' && row.INVOICE_NUMBER < 330 ?
            row.INVOICE_NUMBER : row.PREFIX + '-' + row.INVOICE_NUMBER
          return [
            <button
              className="btn btn-default"
              onClick={() =>
                this.setState({
                  showInvoiceModal: true,
                  invoiceSelected: environment.urlInvoicesResources +
                    row.CUSTOMER_ID +
                    '/' +
                    fileName +
                    '.pdf'
                })
              }
            >
              <i className="entypo-eye"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      },
      {
        name: 'Descargar',
        cell: (row) => {
          const fileName = row.PREFIX === 'FS' && row.INVOICE_NUMBER < 330 ?
            row.INVOICE_NUMBER : row.PREFIX + '-' + row.INVOICE_NUMBER
          return [
            <a
              className="btn btn-default"
              target="_blank"
              href={
                environment.urlInvoicesResources +
                row.CUSTOMER_ID +
                '/' +
                fileName +
                '.pdf'
              }
            >
              <i className="entypo-download"></i>
            </a>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      }
    ]

    if (this.props.app.idRol === '99') {
      this.invoiceColumns.splice(1, 0, {
        name: 'Cliente',
        selector: 'CLINIC_NAME',
        sortable: true
      })
    }
  }

  getInvoicesByDate = (date) => {
    this.setState({ loading: true })
    if (date !== null) {
      this.props.queryParamsHandler.changeQueryParam('date', date)
    }
    const dateFormatServer = date === null ? null : formatServerDate(date)
    getInvoices(dateFormatServer).then((result) => {
      if (result.response) {
        this.setState({
          data: result.invoices
        })
      } else {
        toast('Se ha producido un error al realizar la consulta', {
          type: toast.TYPE.ERROR
        })
      }
    })
  }

  handleOnCloseInvoiceModal = () => {
    this.setState({ showInvoiceModal: false, invoiceSelected: null })
  }

  handleOnCloseReportModal = () => {
    this.setState({ showReportModal: false })
  }

  handleOnGenerateReport = (startDate, endDate) => {
    this.setState({ loading: true })
    generateInvoiceExport(startDate, endDate).then((result) => {
      this.setState({ loading: false, showReportModal: false })
      if (result) {
        if (result.response == 0) {
          toast('No hay facturas que exportar para las fechas seleccionadas.', {
            type: toast.TYPE.ERROR
          })
        } else {
          toast('Se ha producido un error al realizar la consulta', {
            type: toast.TYPE.ERROR
          })
        }
      }
    })
  }

  render() {
    return (
      <Fragment>
        <div className="text-right row-control">
          {this.props.app.idRol == '99' && (
            <>
              <Link to="/factura/1" className="btn btn-primary">
                <i className="entypo-plus"></i> Crear abono
              </Link>
              <Link to="/factura/" className="btn btn-primary">
                <i className="entypo-plus"></i> Crear factura
              </Link>
              <button className="btn btn-primary" onClick={() => this.setState({showReportModal: true})}>
                <i className="entypo-plus"></i> Generar informe
              </button>
            </>
          )}
        </div>
        <Table
          keyFilter={'INVOICE_NUMBER'}
          data={this.state.data}
          columns={this.invoiceColumns}
          title="Facturas"
          extraFields={
            <Input
              hideLabel
              id="inputDate"
              dimensions={{
                label: 0,
                element: 12
              }}
              defaultValue={this.state.date}
              onEnter={(value) => this.getInvoicesByDate(value)}
              placeholder={FORMAT_DATE.toUpperCase()}
              isolateComponent
            />
          }
          pagination
        />
        {this.state.showInvoiceModal && (
          <InvoiceModal
            show={this.state.showInvoiceModal}
            onClose={this.handleOnCloseInvoiceModal}
            data={this.state.invoiceSelected}
          />
        )}
        {this.state.showReportModal && (
          <ReportModal
            show={this.state.showReportModal}
            onClose={this.handleOnCloseReportModal}
            onGenerate={this.handleOnGenerateReport}
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(withQueryParamsHandler(Facturas))
