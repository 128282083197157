
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb'
import { getUsers, setUser } from './../../../resources/services'
import Select from './../../../components/select/select';
import './detail.css';

class UserDetail extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: {
        EMAIL: '',
        PASSWORD: '',
        NAME: '',
        ROLE_ID: ''
      },
      roleOptions: [
        {value: '0',label: 'Not found'},
      ]
    };

    this.ukek = props.match.params.ukey ? props.match.params.ukey : 'new' 
    getUsers(this.ukek)
    .then((result) => {
      if(result.response){
        this.setState(
          {
            data: result.users[0] ? result.users[0] : this.state.data,
            roleOptions: result?.roles ? result.roles.map(v => {
              return { value: v.ID, label: v.NAME }
            }) : this.state.roleOptions
          }
        );
      }else{
        toast('No se han podido recuperar los datos.',{type: toast.TYPE.ERROR})
      }
    })

    this.save = this.save.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target ? event.target : event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const { data } = this.state;
    data[name] = value;
    this.setState({data});
  }

  save(){
    const { data } = this.state;
    // Nuevo usuario
    if(!data.UKEY){
      if(
        !String(data.NAME).length || !String(data.EMAIL).length || !String(data.PASSWORD).length || !String(data.ROLE_ID).length 
      ){
        toast('Rellene todos los campos',{type: toast.TYPE.ERROR})
        return false;
      }

      //email
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(data.EMAIL).toLowerCase())){
        toast('Formato email incorrecto',{type: toast.TYPE.ERROR})
        return false
      }
      data.UKEY = 'NEW';
    }

    setUser(data).then(
      v => {
        if(v.response && v.update){
          toast('Datos guardados correctamente.',{type: toast.TYPE.SUCCESS})
          if(data.UKEY === 'NEW'){
            this.setState({data: {
              EMAIL: '',
              PASSWORD: '',
              NAME: '',
              ROLE_ID: ''
            }});
          }
        }else{
          toast('Error al guardar los datos.',{type: toast.TYPE.ERROR})
        }
      }
    )
  }

  render() {
    return (
      <Fragment>
        {this.props.app.idRol === "99"
          ? <Breadcrumb
              section={[
                {title: "Usuarios", url: "usuarios"},
                {title: "Detalle del usuario"},
              ]}
            />
          : <Breadcrumb
              section={[
                {title: "Detalle del usuario"},
              ]}
            />
        }
        <div className="profile-env">
          {this.props.match.params.ukey ? <h2>Usuario</h2> : <h2>Nuevo usuario</h2>}
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="panel-title">Datos del usuario</div>
            </div>
            <div className="panel-body">
              <form role="form" className="form-horizontal form-groups-bordered" autoComplete="off">
                <div className="form-group" style={{width: '100%'}}>
                  <label htmlFor="field-1" className="col-sm-3 control-label">Nombre</label>
                  <div className="col-sm-5">
                    <input type="text" name="NAME" className="form-control" id="field-1" autoComplete="off" placeholder="" value={this.state.data.NAME} onChange={this.handleInputChange} />
                  </div>
                </div>
                <div className="form-group" style={{width: '100%'}}>
                  <label htmlFor="field-2" className="col-sm-3 control-label">Email</label>
                  <div className="col-sm-5">
                    <input type="email" name="EMAIL" className="form-control" id="field-1" autoComplete="off" placeholder="" value={this.state.data.EMAIL} onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className="form-group" style={{width: '100%'}}>
                  <label htmlFor="field-3" className="col-sm-3 control-label">Contraseña</label>
                  <div className="col-sm-5">
                    <input type="password" name="PASSWORD" className="form-control" id="field-1" placeholder="" autoComplete="off" value={this.state.data.PASSWORD} onChange={this.handleInputChange}/>
                  </div>
                </div>
                {
                  this.props.app.idRol === "99"
                    ? <Fragment><div className="form-group" style={{width: '100%'}}>
                        <label htmlFor="field-1" className="col-sm-3 control-label">Rol</label>
                          <Select
                            name="ROLE_ID"
                            options={this.state.roleOptions}
                            value={this.state.data.ROLE_ID}
                            hideLabel={true}
                            onChange={this.handleInputChange}
                            notSaveInOrder
                          />
                        </div>
                        <Link to="/usuarios/">
                          <button type="button" className="btn btn-primary pull-left" style={{marginTop: '15px'}}>
                            <i className="entypo-left-open"></i> Atrás
                          </button>
                        </Link>
                        <button type="button" className="btn btn-green pull-right" onClick={this.save} style={{marginTop: '15px'}}>
                          Guardar <i className="entypo-check"></i>
                        </button>
                      </Fragment>
                    : null
                }
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  app: state.app
});

export default connect(mapStateToProps)(UserDetail);