
import React, { Component, Fragment } from 'react';
import Table from '../../components/table/table';
import {getPrices, setPrice } from './../../resources/services'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import { toast } from 'react-toastify';

class Precios extends Component {

  constructor(props){
    super(props);
    this.state = {
      loadError: false,
      data:[],
      showModal: false,
      currentRow: {},
      name: '',
      price: '',
      showPriceError: false
    };

    getPrices()
    .then((result) => {
      if(result.response){
        this.setState({
          data: result.materials
        })
      }else{
        toast("Se ha producido un error al realizar la consulta",{type: toast.TYPE.ERROR})
      }
    })

    this.editPrice = this.editPrice.bind(this);
    this.checkPriceAndSave = this.checkPriceAndSave.bind(this);

    this.columns = [
      {
        name: 'Id',
        selector: 'ID',
        sortable: true,
      },
      {
        name: 'Nombre',
        selector: 'NAME',
        sortable: true,
      },
      {
        name: 'Precio',
        selector: 'PRICE',
        sortable: true,
        cell: row => `${row.PRICE}€`,
      },
      {
        name: 'Url Youtube',
        selector: 'URL_YOUTUBE',
        sortable: true,
      },
      {
        cell: (row) => {
          return [
            <button 
              type="button"
              className="btn btn-default"
              onClick={() => this.editPrice(row)}><i className="entypo-pencil"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

  }

  editPrice(row){
    this.setState({currentRow: row, showModal: true, price: row.PRICE, name: row.NAME, urlYoutube: row.URL_YOUTUBE})
  }

  checkPriceAndSave(){
    let realPrice = this.state.price.trim()
    let showPriceError = realPrice.indexOf(",") !== -1 ? true : false;
    showPriceError = realPrice.split(".").lenght > 2 ? true : showPriceError
    this.setState({price: realPrice, showPriceError}, () => {
      if(this.state.showPriceError){
        return
      }
      setPrice({id: this.state.currentRow.ID, price: this.state.price, name: this.state.name, urlYoutube: this.state.urlYoutube})
      .then((result) => {
        if(result.response && result.updated > 0){
          let { data } = this.state;
          const newData = data.map(v => {
            if(v.ID === this.state.currentRow.ID){
              return { ID: v.ID, NAME: this.state.name, PRICE: this.state.price}
            }
            return v;
          });
          this.setState({data: newData, showModal: false, currentRow:{}});
        }else {
          toast("No se ha podido modificar el producto",{type: toast.TYPE.ERROR})
        }
      })
    })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Precios", url: "precios"},
          ]}
        />
        <div>
          <Table
            keyFilter={'NAME'}
            data={this.state.data}
            columns={this.columns}
            title="Materiales"
            pagination
          />
        </div>
        <div className={`modal fade ${this.state.showModal ? 'in' : ''}`} style={{"display":this.state.showModal ? 'block' : 'none'}}>
          <div className="modal-dialog"> 
            <div className="modal-content">
              <div className="modal-header"> 
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.setState({showModal: false, currentRow:{}})}>×</button>
                <h4 className="modal-title">Modificar material</h4>
              </div>
              <div className="modal-body">
                <div>
                  <div style={{"marginRight": "10px", "marginBottom": "15px"}}>Identificador del producto: {this.state.currentRow.ID}</div>
                </div>
                <div>
                  <div style={{"marginRight": "10px"}}>Nombre del material:</div>
                  <input type="text" className="form-control" onChange={(e) => this.setState({name: e.target.value})} defaultValue={this.state.currentRow.NAME} />
                </div>
                <div>
                  <span style={{"marginRight": "10px"}}>Precio del material:</span>
                  <input type="number" className="form-control" onChange={(e) => this.setState({price: e.target.value, showPriceError:false})} defaultValue={this.state.currentRow.PRICE} />
                  {this.state.showPriceError ? <span style={{"marginRight": "10px", "color": "red"}}>Formato del precio no válido ( ejemplo: 12.30€ )</span> : null}
                </div>
                <div>
                  <span style={{"marginRight": "10px"}}>Url youtube:</span>
                  <input type="text" className="form-control" onChange={(e) => this.setState({urlYoutube: e.target.value})} defaultValue={this.state.currentRow.URL_YOUTUBE} />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.checkPriceAndSave}>Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Precios;