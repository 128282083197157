import React from 'react'
import { toast } from 'react-toastify'
import { environment } from '../../../deploy/environment'
import { downloadSources } from '../../../resources/services'

export const DownloadOrderButton = ({
  ukey,
  patientName,
  idSeries,
  onClick,
  type = 'zip',
  sendLabel = false
}) => {
  const send = 'send'
  const urlEtiqueta = `${environment.urlResources}${ukey}/label-${
    sendLabel ? send + '-' : ''
  }number-${idSeries}-${patientName}.pdf`
  const urlZip = environment.urlResourcesZip + '/' + ukey + `/${idSeries}-${patientName}-zip.zip`
  const handleDownloadSources = () => {
    downloadSources({ id: ukey }).then((response) => {
      if (response.response !== 0 && response.create !== 0) {
        if (type === 'etiqueta') {
          window.open(urlEtiqueta, '_blank')
        } else {
          document.getElementById('my_iframe').src = urlZip
        }
      } else {
        toast('No se ha podido descargar el archivo', {
          type: toast.TYPE.ERROR
        })
      }
      onClick && onClick()
    })
  }
  return (
    <button
      type="button"
      className="btn btn-default"
      style={{ marginLeft: '10px' }}
    >
      <i className="entypo-download" onClick={handleDownloadSources}></i>
    </button>
  )
}
