import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './button-selector.css';

// Actions
import { deleteOrderAction, updateOrderAction } from '../../redux/actions/orders';

export default function ButtonSelector(props = {}) {
  const valueName = props.values.name;
  const keyStore = props.name + '_' + valueName;

  // Selector redux para recoger datos en hooks
  const valueSelected = useSelector(state => state.orders[keyStore]);

  // State en hooks
  const [opt, setOpt] = useState({ value: props.values , valueSelected, enabled: props.enabled});
  const dispatch = useDispatch();

  // Save on the store
  const saveAction = (name, value) => {
    dispatch(updateOrderAction({[name]: value}));
    setOpt({ value: props.values , valueSelected: value, enabled: props.enabled});
  }

  // Save on the store
  const deleteAction = (name, value) => {
    dispatch(deleteOrderAction(name))
    setOpt({ value: props.values , valueSelected: undefined, enabled: false });
  }

  // Propaga por cada cambio componentDidMount, componentDidUpdate y componentWillUnmount
  useEffect(() => {
    if(valueSelected !== opt.valueSelected){
      setOpt({ value: props.values , valueSelected, enabled: props.enabled});
      return;
    }

    if(props.enabled !== opt.enabled){
      const value = props.values.value[2].value || props.values.value[2];
      if(!props.enabled){
        
        deleteAction(keyStore, value);
      } else {
        saveAction(keyStore, value)
      }
    }
  }, [props.enabled,props.orders]);

  return (
    <div className="btn-group">
      {opt.value.value.map((v,k) => 
        {
          const label = v.label || v;
          const value = v.value || v;
          const key = `button-selector-${value}-${label}-${k}`;
          return (
            <button 
            type="button" onClick={() => saveAction(keyStore, value)}
            disabled={opt.enabled ? false : true}
            className={`${opt.valueSelected === value ? 'selected' : ''}`}
            key={key}
          >
            {label}
          </button>
          )
        }
      )}
    </div>
  );
}