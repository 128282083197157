import React from 'react'
import Select from '../../../../components/select/select'

export const NewOrderForArreglo = ({ onChange, customers = [], idUser }) => {
  const [data, setData] = React.useState({
    id_cliente: {
      label: 'Cliente',
      value: idUser ?? '',
      id: 'id_cliente',
      element: 'select',
      enabled: !!customers.length > 0,
      options: [...customers]
    },
    nombre_del_paciente: {
      label: 'Nombre',
      value: '',
      id: 'nombre_del_paciente',
      element: 'input',
      enabled: true
    },
    apellidos_del_paciente: {
      label: 'Apellidos',
      value: '',
      id: 'apellidos_del_paciente',
      element: 'input',
      enabled: true
    },
    calzado_del_paciente: {
      label: 'Calzado',
      value: '',
      id: 'calzado_del_paciente',
      element: 'input',
      type: 'number',
      enabled: true
    },
    sexo_del_paciente: {
      label: 'Sexo',
      value: '',
      id: 'sexo_del_paciente',
      element: 'select',
      enabled: true,
      options: [
        { value: 'hombre', label: 'Hombre' },
        { value: 'mujer', label: 'Mujer' }
      ]
    }
  })

  const handleSelectChange = (id, val) => {
    const newData = {
      ...data,
      [id]: {
        ...data[id],
        value: val.value
      }
    }
    setData(newData)
    onChange && onChange(newData)
  }
  const handleInputChange = (e) => {
    const newData = {
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        value: e.target.value
      }
    }
    setData(newData)
    onChange && onChange(newData)
  }

  return (
    <>
      {Object.keys(data).map(
        (key) =>
          data[key].enabled && (
            <div className="form-group" style={{ width: '100%' }} key={key}>
              <label className="col-sm-2 control-label">
                {data[key].label}
              </label>

              {data[key].element === 'input' && (
                <div className="col-sm-6">
                  <input
                    type={data[key].type || 'text'}
                    name={key}
                    className="form-control"
                    placeholder=""
                    maxLength={100}
                    max={data[key].type === 'number' ? 99 : null}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {data[key].element === 'select' && (
                <Select
                  name={key}
                  hideLabel
                  options={data[key].options}
                  value={data[key].value}
                  dimensions={{
                    label: 0,
                    component: 6
                  }}
                  onChange={(value) => handleSelectChange(key, value)}
                  notSaveInOrder
                />
              )}
            </div>
          )
      )}
    </>
  )
}
