import propTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { environment } from "../../../deploy/environment";
import {
  changeStatus,
  createPdf,
  getDeliveries,
  getObject,
} from "../../../resources/services";
import { history } from "./../../../history";

// Actions
import { updateOrderAction } from "../../../redux/actions/orders";

// configuration
import { datos_personales, forros, horma_material_base } from "../../../data";
import { data_antepie } from "../detail/foot-selectors/antepie";
import {
  data_alza,
  data_contencion,
} from "../detail/foot-selectors/contencion";
import { data_mediopie } from "../detail/foot-selectors/mediopie";
import { data_retropie } from "../detail/foot-selectors/retropie";

// CSS
import { USER_ROLES } from "../../../core/constants/roles";
import "./confirmation.css";

class Confirmation extends Component {
  static getCorrectName(name = "") {
    return typeof name === "string" ? name.replace(/[_]+/g, " ") : name;
  }

  constructor(props) {
    super(props);
    this.state = {
      pdfCreated: false,
      loading: true,
      sended: props.orders.sended,
      deliveries: [],
    };
  }

  componentDidMount() {
    const { idRol, idUser } = this.props.app;
    const dataToSend = JSON.stringify({
      datos_personales: getObject(datos_personales),
      horma_material_base: getObject(horma_material_base),
      forros: getObject(forros),
      antepie: getObject([data_antepie]),
      contencion: getObject([data_contencion]),
      alza: getObject([data_alza]),
      mediopie: getObject([data_mediopie]),
      retropie: getObject([data_retropie]),
      UKEY: localStorage.getItem("ukey"),
      order: this.props.orders,
    });
    createPdf(dataToSend).then((v) => {
      this.setState({ createPdf: true });
    });
    getDeliveries(idUser, 0, null, null, null, true).then((v) =>
      this.setState({ deliveries: v.deliveries, loading: false })
    );
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  backToEdit = () => {
    if (this.state.sended) {
      history.goBack();
      // history.goBack()
    } else {
      this.props.updateOrder({ step: 0 });
    }
  };

  sendOrderClick = (deliveryId = null) => {
    const { idUser } = this.props.app;
    this.setState({ loading: true });
    const id = this.props.orders.id;
    const status =
      (this.props.app.idRol === "99" || this.props.app.idRol === "4") &&
      this.props.orders.status === "1"
        ? 2
        : 1;
    let dataToSend = { id, status, UKEY: idUser, send: true };
    if (deliveryId) {
      dataToSend = { ...dataToSend, deliveryId };
    }
    const r = window.confirm(
      "Está seguro? Si confirma no podrá volver a modificar el pedido."
    );
    if (r == true) {
      changeStatus(dataToSend).then((response) => {
        if (response.response === 1 && response.update === 1) {
          this.props.updateOrder({ step: 1 });
          this.setState({ loading: false, sended: true });
          toast("El pedido se ha enviado correctamente", {
            type: toast.TYPE.SUCCESS,
          });
        } else {
          toast("No se ha podido actualizar el status del pedido", {
            type: toast.TYPE.ERROR,
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };

  checkSendOrder = () => {
    this.sendOrderClick();
  };

  render() {
    const isAdmin = this.props.app.idRol === USER_ROLES.ADMIN;
    const fileName = `${isAdmin ? "admin-" : ""}order-number-${
      this.props.orders?.id_series
    }-${this.props.orders?.nombre_del_paciente} ${
      this.props.orders?.apellidos_del_paciente
    }.pdf`;

    return (
      <Fragment>
        <ul className="pager wizard" style={{ marginTop: "20px" }}>
          <li className="previous">
            <a href="#" onClick={this.backToEdit}>
              <i className="entypo-left-open"></i> Atrás
            </a>
          </li>
          <li className="pull-right sendButton">
            {this.state.sended ? (
              <button
                type="button"
                className=" btn btn-default btn-icon sendButton"
                disabled={true}
              >
                El pedido ha sido enviado
                <i className="entypo-check"></i>{" "}
              </button>
            ) : (
              <button
                type="button"
                onClick={this.checkSendOrder}
                className=" btn btn-green btn-icon sendButton"
                disabled={this.state.loading}
              >
                Enviar pedido
                <i className="entypo-check"></i>{" "}
              </button>
            )}
          </li>
        </ul>
        <div id="mailContent">
          {
            !this.state.createPdf ? (
              <div className="gooey">
                <span className="dot"></span>
                <div className="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            ) : (
              <object
                data={`${environment.root}/api/files/order-attachment/${this.props.orders.id}/${fileName}`}
                type="application/pdf"
                style={{ width: "100%", height: "calc(100vh - 270px)" }}
              />
            )
            //: <object data={`${environment.root}/api/files/order-pdfs/ejemplo.pdf`} type="application/pdf" width="100%" height="500" />
          }
        </div>
      </Fragment>
    );
  }
}

Confirmation.propTypes = {
  data: propTypes.array,
};

Confirmation.defaultProps = {
  data: [],
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  app: state.app,
});

const mapDispatchToProps = {
  updateOrder: updateOrderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
