import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import NotFound from './../pages/404/404'
import Control from './../pages/control/index'
import CustomerDetail from './../pages/customers/detail/index'
import Customers from './../pages/customers/index'
import Enviados from './../pages/enviados/index'
import Fabricacion from './../pages/fabricacion/index'
import Facturas from './../pages/facturas/index'
import FacturaManualDetail from './../pages/facturas/manual/index'
import Favoritos from './../pages/favoritos/index'
import Finalizados from './../pages/finalizados/index'
import Login from './../pages/login/index'
import Arreglo from './../pages/orders/arreglo'
import Precios from './../pages/precios/index'
import UserDetail from './../pages/users/detail/index'
import Users from './../pages/users/index'
import { isLogged } from './../resources/services'
import Container from './../sections/container/container'
import Order from './orders/detail/index'
import Orders from './orders/index'
import Podologists from './podologist'
import PodologistDetail from './podologist/detail'

// Actions
import { updateAppAction } from './../redux/actions/app'

//css
import 'react-toastify/dist/ReactToastify.css'
import { USER_ROLES } from '../core/constants/roles'
import svgLoading from './../assets/images/logo-login.png'
import './main.css'

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false
    }
    this.accessRole = [
      {
        name: 'pedidos',
        role: '*'
      },
      {
        name: 'detalle-pedido',
        role: '*'
      },
      {
        name: 'usuarios',
        role: ['99']
      },
      {
        name: 'detalle-usuario',
        role: ['99', '2', '3', '4']
      },
      {
        name: 'clientes',
        role: ['99', '4']
      },
      {
        name: 'detalle-cliente',
        role: ['99', '1', '4']
      },
      {
        name: 'podologos',
        role: ['99', '1']
      },
      {
        name: 'detalle-podologo',
        role: ['99', '1']
      },
      {
        name: 'precios',
        role: ['99']
      },
      {
        name: 'fabricacion',
        role: ['99', '2']
      },
      {
        name: 'control-de-calidad',
        role: ['99', '3']
      },
      {
        name: 'finalizados',
        role: ['99', '4', '3']
      },
      {
        name: 'favoritos',
        role: ['99', '1', '4']
      },
      {
        name: 'enviados',
        role: ['99', '4']
      },
      {
        name: 'facturas',
        role: [USER_ROLES.ADMIN, USER_ROLES.CLIENT]
      },
      {
        name: 'factura',
        role: [USER_ROLES.ADMIN]
      },
      {
        name: 'arreglo',
        role: [USER_ROLES.ADMIN, USER_ROLES.DESIGNER, USER_ROLES.CLIENT]
      }
    ]

    this.checkAccess = this.checkAccess.bind(this)

    toast.configure({
      autoClose: 4000,
      draggable: false
      //etc you get the idea
    })
  }

  componentWillMount() {
    isLogged().then((response) => {
      if (response.login) {
        this.props.updateApp({
          logged: true,
          idRol: response.r,
          idUser: response.u,
          name: response.n,
          logo: response.l
        })
      }
      this.setState({ ready: true })
    })
  }

  checkAccess(name) {
    return !this.props.app.idRol
      ? 0
      : this.accessRole.find(
          (v) =>
            v.name === name &&
            (v.role === '*' || v.role.find((v2) => v2 === this.props.app.idRol))
        )
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => {
      isLogged().then((response) => {
        this.checkAccess(rest.name)
        if (!response.login) {
          this.props.updateApp({
            logged: false,
            idRol: 0
          })
        }
      })
      return (
        <Route
          {...rest}
          render={(props) =>
            this.props.app.logged ? (
              this.checkAccess(rest.name) &&
              this.checkAccess(rest.name) !== 0 ? (
                <Container {...rest}>
                  <Component {...props} key={`component-${rest.name}`} />
                </Container>
              ) : (
                <Redirect
                  to={{
                    pathname: '/404/'
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: '/'
                }}
              />
            )
          }
        />
      )
    }

    if (!this.state.ready) {
      return (
        <div className="mainLoading">
          <img
            className="logo-loading"
            alt="loading"
            src={svgLoading}
            width="200"
          />
        </div>
      )
    }
    return (
      <Fragment>
        <ToastContainer />
        <Switch>
          <Route path="/" component={Login} exact />
          <PrivateRoute path="/pedidos/" component={Orders} name="pedidos" />
          <PrivateRoute
            path="/pedido/arreglo/:ukey?"
            component={Arreglo}
            name="arreglo"
          />
          <PrivateRoute
            path="/pedido/:ukey?"
            component={Order}
            name="detalle-pedido"
          />
          <PrivateRoute path="/usuarios/" component={Users} name="usuarios" />
          <PrivateRoute
            path="/usuario/:ukey?"
            component={UserDetail}
            name="detalle-usuario"
          />
          <PrivateRoute
            path="/clientes/"
            component={Customers}
            name="clientes"
          />
          <PrivateRoute
            path="/cliente/:ukey?/"
            component={CustomerDetail}
            name="detalle-cliente"
          />
          <PrivateRoute
            path="/podologos/"
            component={Podologists}
            name="podologos"
          />
          <PrivateRoute
            path="/podologo/:ukey?/"
            component={PodologistDetail}
            name="detalle-podologo"
          />
          <PrivateRoute path="/precios/" component={Precios} name="precios" />
          <PrivateRoute
            path="/fabricacion/"
            component={Fabricacion}
            name="fabricacion"
          />
          <PrivateRoute
            path="/control-de-calidad/"
            component={Control}
            name="control-de-calidad"
          />
          <PrivateRoute
            path="/finalizados/"
            component={Finalizados}
            name="finalizados"
          />
          <PrivateRoute
            path="/favoritos/"
            component={Favoritos}
            name="favoritos"
          />
          <PrivateRoute
            path="/enviados/"
            component={Enviados}
            name="enviados"
          />
          <PrivateRoute
            path="/facturas/"
            component={Facturas}
            name="facturas"
          />
          <PrivateRoute
            path="/factura/:isRefund?"
            component={FacturaManualDetail}
            name="factura"
          />

          <Route path="*" component={NotFound} />
        </Switch>
        {(window.location.pathname === '' ||
          window.location.pathname === '/') &&
        this.props.app.logged &&
        (this.props.app.idRol === '4' || this.props.app.idRol === '99') ? (
          <Redirect
            to={{
              pathname: '/pedidos'
            }}
          />
        ) : null}
        {(window.location.pathname !== '/fabricacion' ||
          window.location.pathname !== '/fabricacion/') &&
        this.props.app.logged &&
        this.props.app.idRol === '2' ? (
          <Redirect
            to={{
              pathname: '/fabricacion'
            }}
          />
        ) : null}
        {window.location.pathname.indexOf('/favoritos') === -1 &&
        window.location.pathname.indexOf('/cliente/') === -1 &&
        window.location.pathname.indexOf('/podologo/') === -1 &&
        window.location.pathname.indexOf('/pedido') === -1 &&
        this.props.app.logged &&
        this.props.app.idRol === '1' ? (
          <Redirect
            to={{
              pathname: '/pedidos'
            }}
          />
        ) : null}
        {window.location.pathname.indexOf('/control-de-calidad') === -1 &&
        window.location.pathname.indexOf('/finalizados') === -1 &&
        this.props.app.logged &&
        this.props.app.idRol === '3' ? (
          <Redirect
            to={{
              pathname: '/control-de-calidad'
            }}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

const mapDispatchToProps = {
  updateApp: updateAppAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
