import React from "react";
import { toast } from "react-toastify";
import { duplicateOrder } from "../../../resources/services";
import { useHistory } from "react-router-dom";

export const DuplicateOrderButton = ({ ukey }) => {
  const history = useHistory();
  const handleDuplicateOrder = (id) => {
    const ok = window.confirm(`Estás seguro de que deseas duplicar el pedido?`);
    if (ok) {
      duplicateOrder({ id }).then((result) => {
        if (result.response !== 0 && result.copied > 0) {
          history.push("/pedido/" + result.newId);
        } else {
          toast("No se ha podido duplicar el pedido", {
            type: toast.TYPE.ERROR,
          });
        }
      });
    }
  };

  return (
    <button
      type="button"
      className="btn btn-default"
      style={{ marginLeft: "10px" }}
    >
      <i className="entypo-docs" onClick={() => handleDuplicateOrder(ukey)}></i>
    </button>
  );
};
