
import React from 'react';

//css
import './404.css';

function NotFound() {;
  
  return (
    <div className="main-content">
      <div className="page-error-404">
        <div className="error-symbol">
          <i className="entypo-attention"></i> 
        </div> 
        <div className="error-text">
          <h2>404</h2>
          <p>Página no encontrada</p>
        </div>
      </div>
    </div>  
  );
}

export default NotFound;