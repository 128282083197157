import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { updateOrderAction } from '../../redux/actions/orders';

class TextArea extends Component {

  constructor(props){
    super(props);
    this.state = {
      id: props.id,
      dimension: props.dimension,
      value: ''
    }
  }

  onBlur = (e) => {
    if(this.props.isolateComponent) return false;
    this.props.updateOrder({
        [this.props.name]: e.target.value
    })
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value);
  }
  
  render() {
    const { orders, name, placeholder, defaultValue, disabled} = this.props;
    return (
        <Fragment>
            <div className={`col-xs-12 col-sm-${this.props.dimension}`}>
                <textarea 
                    className="form-control"
                    id={this.props.id}
                    placeholder={placeholder}
                    style={this.props.style}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    name={name}
                    disabled={disabled}
                    defaultValue={defaultValue || orders[name]}
                />
            </div>
        </Fragment>
    );
  }
}

TextArea.propTypes = {
    placeholder: propTypes.string,
    name: propTypes.string,
    type: propTypes.string,
    id: propTypes.string,
    label: propTypes.string,
    dimensions: propTypes.object,
    hideLabel: propTypes.bool
};
  
TextArea.defaultProps = {
    placeholder: 'Escribe un texto',
    name: '',
    type: 'text',
    id: '',
    label: 'Label',
    dimensions: {
        label: 2,
        element: 3,
    },
    hideLabel: false
};

const mapStateToProps = state => ({
  orders: state.orders,
});
const mapDispatchToProps = {
  updateOrder: updateOrderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextArea);