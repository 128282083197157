import React from 'react'
import { Link } from 'react-router-dom'

export const DetailOrderButton = ({ ukey, status = '' }) => {
  return (
    <Link to={`/pedido/${ukey}`} key={`link-${ukey}`}>
      <button
        type="button"
        className="btn btn-default"
        style={{ marginLeft: '10px' }}
      >
        <i className={status === '5' ? 'entypo-pencil' : 'entypo-eye'}></i>
      </button>
    </Link>
  )
}
