import propTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Actions
import { updateOrderAction } from '../../redux/actions/orders'

// COMPONENTS
import CanvasFoot from '../../components/canvas-foot/canvas-foot'
import Group from './../../components/group/group'

// STYLES
import './wizard.css'

// JS
import { ORDER_TYPE } from '../../core/constants/roles'
import { isAlphabetical } from '../../core/utils/validations'
import './wizard-jquery.js'

class Wizard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      completed: [],
      totalSteps: 7,
      loading: false
    }
  }

  getBar = () => {
    return this.props.data.map((v, k) => {
      let active = k === this.state.currentStep ? 'active' : ''
      active = this.state.currentStep > k ? 'completed' : active

      return (
        <li
          className={`${active}`}
          key={`li_wizard_${k}`}
          onClick={() => this.changeStep(k)}
        >
          <a
            //href={`#tab${k}`}
            data-toggle={`tab`}
            style={{ cursor: 'pointer' }}
          >
            <span>{k + 1}</span>
            {v.title}
          </a>
        </li>
      )
    })
  }

  getCanvas = (k, v, active) => {
    if (Array.isArray(v.canvas)) {
      return (
        <Fragment key={`div_c_wizard_${k}`}>
          <div className={`tab-pane ${active}`} id={`tab${k}`}>
            <div className="panel panel-primary" data-collapsed="0">
              <div className="panel-heading">
                <div className="panel-title">{v.canvas[0].title}</div>
                <div className="panel-options">
                  {/* <a href="#" data-rel="collapse"><i className="entypo-down-open" /></a>
                                    <a href="#" data-rel="reload"><i className="entypo-arrows-ccw" /></a> */}
                </div>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12">
                    <CanvasFoot data={v.canvas[0].data} />
                  </div>
                </div>
              </div>
            </div>

            <div className="panel panel-primary" data-collapsed="0">
              <div className="panel-heading">
                <div className="panel-title">{v.canvas[1].title}</div>
                <div className="panel-options">
                  {/*<a href="#" data-rel="collapse"><i className="entypo-down-open" /></a>
                                     <a href="#" data-rel="reload"><i className="entypo-arrows-ccw" /></a> */}
                </div>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12">
                    <CanvasFoot data={v.canvas[1].data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
    return (
      <div
        className={`tab-pane ${active}`}
        id={`tab${k}`}
        key={`div_wizard_${k}`}
      >
        <div className="panel panel-primary" data-collapsed="0">
          <div className="panel-heading">
            <div className="panel-title">{v.title}</div>
            <div className="panel-options">
              {/* <a href="#" data-rel="collapse"><i className="entypo-down-open" /></a>
                             <a href="#" data-rel="reload"><i className="entypo-arrows-ccw" /></a> */}
            </div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-xs-12">
                <CanvasFoot data={v.canvas} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getGroups = () => {
    return this.props.data.map((v, k) => {
      const active = k === this.state.currentStep ? 'active' : ''
      if (typeof v.canvas !== 'undefined') {
        return this.getCanvas(k, v, active)
      }
      return (
        <div
          className={`tab-pane ${active}`}
          id={`tab${k}`}
          key={`group_wizard_${k}`}
        >
          <Group data={v.group} />
        </div>
      )
    })
  }

  goToConfirmation = () => {
    if (this.validateSteps()) {
      this.props.updateOrder({ step: 1, order_type: ORDER_TYPE.NEW })
    }
  }

  validateSteps = () => {
    switch (this.state.currentStep) {
      case 0:
        if (
          !this.props.orders.nombre_del_paciente ||
          this.props.orders?.nombre_del_paciente === '' ||
          !this.props.orders.apellidos_del_paciente ||
          this.props.orders?.apellidos_del_paciente === '' ||
          !this.props.orders.calzado_del_paciente ||
          this.props.orders?.calzado_del_paciente === '' ||
          !this.props.orders.sexo_del_paciente ||
          this.props.orders?.sexo_del_paciente === ''
        ) {
          alert('Por favor, rellene todos los datos del paciente')
          return false
        }
        if (
          this.props.app.idRol === '99' &&
          (!this.props.orders.id_cliente ||
            this.props.orders?.id_cliente === '')
        ) {
          alert('Por favor, seleccione el cliente')
          return false
        }
        if (
          !isAlphabetical(this.props.orders.nombre_del_paciente) ||
          !isAlphabetical(this.props.orders.apellidos_del_paciente)
        ) {
          alert(
            'El Nombre y apellido solo puede contener caracteres alfabeticos'
          )
          return false
        }
        break

      case 3:
        const isCuñaActivated =
          this.props.orders.cuña_supinadora_antepie ||
          this.props.orders.cuña_pronadora_antepie
        if (isCuñaActivated && !this.props.orders.cuña_grosor) {
          alert('Por favor, seleccione el grosor de la cuña')
          return false
        }
      case 4:
        const isCuñaSupinadoraMedioPieActivated =
          this.props.orders.cuña_supinadora_extrinsica_mediopie ||
          this.props.orders.cuña_pronadora_extrinsica_mediopie
        const isCuñaExtrinsicaCompleta =
          this.props.orders.cuña_supinadora_extrinsica_completa ||
          this.props.orders.cuña_pronadora_extrinsica_completa
        const isCuñaIntrinsica =
          this.props.orders.cuña_pronadora_intrinsica ||
          this.props.orders.cuña_supinadora_intrinsica
        const isAleta =
          this.props.orders.aleta_interna || this.props.orders.aleta_externa

        if (isCuñaExtrinsicaCompleta && !this.props.orders.grosor_extrinseco) {
          alert(
            'Por favor, seleccione el grosor de la cuña extrinsica completa'
          )
          return false
        }
        if (isCuñaIntrinsica && !this.props.orders.grosor_intrinseco) {
          alert('Por favor, seleccione el grosor de la cuña intrinsica')
          return false
        }
        if (
          isCuñaSupinadoraMedioPieActivated &&
          !this.props.orders.grosor_mediopie_extrinseco
        ) {
          alert(
            'Por favor, seleccione el grosor de la cuña supinadora medio pie'
          )
          return false
        }
        if (isAleta && !this.props.orders.grosor_aleta) {
          alert('Por favor, seleccione el grosor de la aleta')
          return false
        }
      case 6:
        const isAlzaCompletaStandardardSelectect =
          this.props.orders.alza_completa_standard

        if (
          isAlzaCompletaStandardardSelectect &&
          !this.props.orders.altura_alza_completa_standard
        ) {
          alert('Por favor, seleccione la altura de el alza completa standard')
          return false
        }
      default:
        break
    }
    return true
  }

  changeStep(step) {
    if (this.validateSteps()) {
      this.setState({ currentStep: step })
    }
  }

  nextStep = () => {
    if (this.validateSteps()) {
      this.setState({ currentStep: this.state.currentStep + 1 })
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { currentStep } = this.state
    return this.state.loading ? (
      <div className="gooey">
        <span className="dot"></span>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    ) : (
      <form
        id="rootwizard"
        method="post"
        action=""
        className="form-wizard validate"
      >
        <div className="steps-progress">
          <div
            className="progress-indicator"
            style={{
              width: `${
                (this.state.currentStep * 100) / (this.state.totalSteps - 1)
              }%`
            }}
          ></div>
        </div>
        <ul>{this.getBar()}</ul>
        <div className="tab-content">
          {this.getGroups()}
          <ul className="pager wizard">
            <li className="previous">
              {this.state.currentStep > 0 ? (
                <button
                  type="button"
                  className="btn btn-primary pull-left"
                  onClick={() =>
                    this.setState({ currentStep: currentStep - 1 })
                  }
                >
                  <i className="entypo-left-open"></i> Atrás
                </button>
              ) : null}
            </li>
            {this.state.currentStep < this.state.totalSteps - 1 ? (
              <li className="next nextButton">
                <button
                  type="button"
                  className="btn btn-primary pull-right"
                  onClick={this.nextStep}
                >
                  Siguiente <i className="entypo-right-open"></i>
                </button>
              </li>
            ) : (
              <li className="pull-right sendButton">
                <button
                  type="button"
                  onClick={this.goToConfirmation}
                  className="btn btn-blue btn-icon sendButton"
                >
                  Ver resumen
                  <i className="entypo-right"></i>{' '}
                </button>
              </li>
            )}
          </ul>
        </div>
        <input
          type="hidden"
          name="form-data"
          value={this.props.orders.data_send}
        />
      </form>
    )
  }
}

Wizard.propTypes = {
  data: propTypes.array
}

Wizard.defaultProps = {
  data: []
}

const mapStateToProps = (state) => ({
  orders: state.orders,
  app: state.app
})
const mapDispatchToProps = {
  updateOrder: updateOrderAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard)
