
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Table from '../../components/table/table';
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import { getUsers, deleteUser } from './../../resources/services'

class Users extends Component {

  constructor(props){
    super(props);
    this.state = {
      data:[]
    };

    getUsers()
    .then((result) => {
      if(result.response){
        this.setState({
          data: result.users
        })
      }else{
        toast("Error al recuperar los datos",{type: toast.TYPE.ERROR})
      }
    })

    this.deleteUser = this.deleteUser.bind(this);

    this.columns = [
      {
        name: 'Nombre',
        selector: 'NAME',
        sortable: true,
      },
      {
        name: 'Email',
        selector: 'EMAIL',
        sortable: true,
      },
      {
        name: 'Rol',
        selector: 'ROLE_NAME',
        sortable: true,
      },
      {
        cell: (row) => {
          return [
            <Link to={`/usuario/${row.UKEY}`} key={`table-link-${row.UKEY}`}><button type="button" className="btn btn-default" style={{marginRight:'10px'}}><i className="entypo-pencil"></i></button></Link>,
            row.ROLE_ID !== '99' 
              ? 
                <button 
                  type="button"
                  className="btn btn-red"
                  key={`table-button-${row.UKEY}`}
                  onClick={() => this.deleteUser(row)}><i className="entypo-trash"></i></button> 
              : null
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

  }

  deleteUser(json){
    window.confirm(`Estás seguro de que deseas eliminar el usuario ${json.NAME}?`) && deleteUser({u:json.UKEY})
      .then((result) => {
        if(result.response && result.deleted != 0){
          let { data } = this.state;
          data = data.filter(v => v.UKEY !== json.UKEY);
          this.setState({data});
          toast('Se ha eliminado '+result.deleted+' registro.',{type: toast.TYPE.SUCCESS})
        } else {
          toast('Error al borrar el registro.',{type: toast.TYPE.ERROR})
        }
      })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Usuarios", url: "usuarios"},
          ]}
        />
        <div className="text-right">
          <Link to="/usuario/" className="btn btn-primary"> 
            <i className="entypo-plus"></i> Añadir usuario
          </Link>
        </div>
        <Table
          keyFilter={'EMAIL'}
          data={this.state.data}
          columns={this.columns}
          title="Usuarios"
          pagination
        />
      </Fragment>
    );
  }
}

export default Users;