import React from "react";
import { toast } from "react-toastify";
import Select from "../../../components/select/select";
import { changeStatus } from "../../../resources/services";

export const ChangeStatusOrderButton = ({ onChangeStatus, ukey, status, statusOptions }) => {
  const handleSelectChange = (data) => {
    const id = ukey;
    const dataToSend = {
      id,
      status: data.value,
      UKEY: localStorage.getItem("ukey"),
    };

    changeStatus(dataToSend).then((result) => {
      if (result.response !== 0 && result.update !== 0) {
        onChangeStatus && onChangeStatus()
      } else {
        toast("No se ha podido obtener los estados del pedidio", {
          type: toast.TYPE.ERROR,
        });
      }
    });
  };
  return (
    <div className="selectChangeStatus">
      <Select
        name="changeStatus"
        options={statusOptions}
        value={`${status}`}
        hideLabel={true}
        dimensions={{ element: 6 }}
        onChange={handleSelectChange}
        notSaveInOrder={true}
      />
    </div>
  );
};
