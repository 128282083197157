import propTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import { environment as ENV } from '../../deploy/environment'
import { getFiles } from '../../resources/services'
import { environment } from './../../deploy/environment'

// Actions
import {
  deleteOrderAction,
  updateOrderAction
} from '../../redux/actions/orders'

const dropzoneContainer = {
  padding: '10px 20px'
}

const dropzoneStyles = {
  width: '100%',
  minHeight: '100px',
  border: '1px dashed #949494',
  background: '#f9f8f8',
  cursor: 'pointer'
}

const p = {
  padding: '41px',
  margin: '0 auto',
  display: 'table'
}

const { root } = environment

function UploadFile(props) {
  // Updated files
  const [files, setFile] = useState(props.files ? props.files : [])
  const [ukey, setUkey] = useState(
    props?.options?.ukey ? props.options.ukey : ''
  )
  const [loading, setLoading] = useState(true)

  const { getRootProps, getInputProps } = useDropzone({
    multiple: props.multiple,
    maxSize: props.maxSize,
    onDrop: (files) => {
      console.log(files)
      upload(files)
    },
    onDropRejected: (file) => {
      console.log(file)
      if (file.length === 0) {
        alert('Error al analizar el archivo')
      }
      if (file[0].size > props.maxSize) {
        alert(
          'El archivo es superior a ' +
            Math.round(props.maxSize / 1000000) +
            'MB'
        )
      } else {
        console.log(file[0].size, props.maxSize, file)
        alert('Formato del archivo no aceptado')
      }
    },
    // accept: props.formatRequired.length > 0 ? props.formatRequired : ['*'],
    required: props.required
  })

  const deleteImg = (file) => {
    const formData = new FormData()
    formData.append('file', file.filename)
    formData.append('type', props.type)
    formData.append('ukey', ukey)
    return fetch(root + '/api/ajax/deleteFiles/', {
      method: 'POST',
      headers: {
        ukey: window.localStorage.getItem('ukey'),
        version: 1
      },
      body: formData
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          alert('Error en la consulta')
        }
      })
      .then((response) => {
        if (response.response && response.removed) {
          const filesFiltered = files.filter(
            (v) => v.filename !== file.filename
          )
          props.onUpdatedfile(filesFiltered)
          setFile(filesFiltered)
          if (props.setOnStore) {
            props.updateOrder({
              [props.name]: filesFiltered
            })
          }
        } else {
          alert('Error en la consulta')
        }
      })
      .catch(() => alert('Error en la consulta'))
  }

  const upload = (file) => {
    setLoading(true)
    if (props.needUkey && (!props?.order?.id || props?.order?.id === '')) {
      alert(
        'Por favor, rellene todos los datos del paciente antes de adjuntar cualquier documento.'
      )
      return false
    }

    const options = { ...props.options }
    if (props.needUkey && ukey === '') {
      setUkey(props.order.id)
      options.ukey = props.order.id
    }

    const promises = []
    file.forEach((v, k) => {
      promises.push(
        new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('file', v)
          formData.append('type', props.type)
          formData.append('name', props.name)
          formData.append('multiple', props.multiple ? 1 : 0)
          formData.append('options', JSON.stringify(options))
          return fetch(root + '/api/ajax/upload-files/', {
            method: 'POST',
            headers: {
              ukey: window.localStorage.getItem('ukey'),
              version: '1'
            },
            body: formData
          })
            .then((response) => {
              if (response.status === 200) {
                return response.json()
              } else {
                reject(v.name)
              }
            })
            .then((response) => {
              if (response.upload === 0 || response.response === 0) {
                reject('Upload 0')
                return false
              }
              resolve({ filename: response.file, path: response.path })
            })
        })
      )
    })

    Promise.all(promises)
      .then((values) => {
        let filesUploadeds = []
        if (props.multiple) {
          filesUploadeds = [...files]
        }
        filesUploadeds = [...filesUploadeds, ...values]
        setFile(filesUploadeds)
        props.onUpdatedfile(filesUploadeds)
        if (props.setOnStore) {
          props.updateOrder({
            [props.name]: filesUploadeds
          })
        }
        setLoading(false)
      })
      .catch((filename) =>
        alert('Ocurrió un problema al cargar el archivo ' + filename)
      )
  }

  useEffect(() => {
    getFiles(props.type, ukey)
      .then((response) => {
        if (response.response === 0) {
          setLoading(false)
          console.log('Error on getfiles')
          return
        }
        const files = Object.keys(response.files).map((v) => ({
          filename: response.files[v],
          path: response.path + response.files[v]
        }))
        setFile(files)
        setLoading(false)
        props.onFilesLoaded(files)
      })
      .catch((err) => console.error('Error on getFiles', err))
  }, [])

  const listFiles = files.map((file, key) => {
    return (
      <li key={`${key}-${file.filename}`}>
        {file.filename} -{' '}
        <a
          href={ENV.rootFiles + '/' + file.path}
          style={{ color: 'blue', cursor: 'pointer' }}
          target="_blank"
        >
          Ver
        </a>{' '}
        {!props.readOnly ? (
          <Fragment>
            {' '}
            -{' '}
            <a
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => deleteImg(file)}
            >
              Eliminar
            </a>
          </Fragment>
        ) : null}
      </li>
    )
  })

  // Comprobamos que existe un archivo con un "x" formato
  const requireFormat =
    props.required && props.formatRequired
      ? !files.some((v) => props.formatRequired.indexOf(v.type) !== -1)
      : false

  return (
    <section
      style={dropzoneContainer}
      className="upload-file"
      data-required={'false'}
      data-value={props.files.length ? true : false}
      data-message={'Por favor, insesrte el archivo del escaner'}
      data-require-format={requireFormat}
    >
      {props.readOnly ? null : (
        <div
          {...getRootProps({ className: 'dropzone' })}
          style={dropzoneStyles}
        >
          <input {...getInputProps()} required={props.required} />
          <p style={p}>
            {props.text} (Max {props.maxSize / 1000000}MB)
          </p>
        </div>
      )}
      {props.simple ? null : (
        <aside>
          {files.length !== 0 ? (
            <div>
              <h5>
                <b>Archivos adjuntados:</b>
              </h5>
              <ul>{listFiles}</ul>
            </div>
          ) : null}
          {loading ? <div>Loading...</div> : null}
        </aside>
      )}
    </section>
  )
}

UploadFile.propTypes = {
  files: propTypes.array,
  multiple: propTypes.bool,
  required: propTypes.bool,
  formatRequired: propTypes.array,
  maxSize: propTypes.number,
  onUpdatedfile: propTypes.func,
  onFilesLoaded: propTypes.func,
  options: propTypes.object,
  setOnStore: propTypes.bool
}

UploadFile.defaultProps = {
  files: [],
  multiple: false,
  required: false,
  formatRequired: [],
  maxSize: 500000,
  onUpdatedfile: () => {},
  onFilesLoaded: () => {},
  options: {},
  setOnStore: false
}

const mapStateToProps = (state) => ({
  order: state.orders
})

const mapDispatchToProps = {
  updateOrder: updateOrderAction,
  deleteOrder: deleteOrderAction
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile)
