import { ORDER_STATUS } from '../constants/roles';
import { formatDateToUnix } from './date';

export const sortCreatedOrder = (rowA, rowB) => {
  const rowADate =
    rowA.STATUS === ORDER_STATUS.PROPOSAL
      ? formatDateToUnix(rowA.FECHA_CREACION)
      : formatDateToUnix(rowA.FECHA_DISENO);
  const rowBDate =
    rowB.STATUS === ORDER_STATUS.PROPOSAL
      ? formatDateToUnix(rowB.FECHA_CREACION)
      : formatDateToUnix(rowB.FECHA_DISENO);
  return rowADate - rowBDate;
};
