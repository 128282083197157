import React, { useState, useEffect } from 'react';
import './alert.css';

function Alert(props = {}) {
  const [show, setShow] = useState(props.show ? true : false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <div className={`alert`} onClick={() => setShow(false)} style={{'display': show ? 'fixed' : 'none'}}>
      <div className={`content alert-${props.status}`} style={{'display': show ? 'inline' : 'none'}}>
        {props.message}
      </div>
    </div>
  );
}

export default Alert;