// MEDIOPIE
import almendrita from "./../img/shadows-png/MEDIOPIE/almendrita.png";
import barraRetrocapital from "./../img/shadows-png/MEDIOPIE/barra_retrocapital.png";
import bobedaRellenaPoron from "./../img/shadows-png/MEDIOPIE/boveda_rellena_poron.png";
import cunaPronadoraExtrinsica from "./../img/shadows-png/MEDIOPIE/cuna_pronadora_extrinsica_completa.png";
import cunaSupinadoraExtrinsica from "./../img/shadows-png/MEDIOPIE/cuna_supinadora_extrinsica_completa.png";
import descargaEstiloides from "./../img/shadows-png/MEDIOPIE/descarga_estiloides.png";
import cuna_ext_larga from "./../img/shadows-png/RETROPIE/cuna_ext_larga.png";
import cuna_int_larga from "./../img/shadows-png/RETROPIE/cuna_int_larga.png";

export const data_mediopie = {
  title: "Mediopié",
  dimension: 12,
  components: [
    {
      properties: {
        dimensions: {
          label: 1,
          element: 11,
        },
        label: "",
        type: "checkbox",
        group: [
          {
            title: "Balances",
          },
          {
            type: "select",
            label: "Balances en inversión",
            value: "balances_inversion",
            name: "balances_inversion",
            hideLabel: true,
            unselectable: true,
            placeholder: "Balances...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "1º",
                value: "MPBE1",
              },
              {
                label: "2º",
                value: "MPBE2",
              },
              {
                label: "3º",
                value: "MPBE3",
              },
              {
                label: "4º",
                value: "MPBE4",
              },
              {
                label: "5º",
                value: "MPBE5",
              },
              {
                label: "6º",
                value: "MPBE6",
              },
              {
                label: "7º",
                value: "MPBE7",
              },
              {
                label: "8º",
                value: "MPBE8",
              },
              {
                label: "9º",
                value: "MPBE9",
              },
              {
                label: "10º",
                value: "MPBE10",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "select",
            label: "Balances en eversión",
            value: "balances_eversion",
            name: "balances_eversion",
            hideLabel: true,
            placeholder: "Balances...",
            unselectable: true,
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "1º",
                value: "MPBE1",
              },
              {
                label: "2º",
                value: "MPBE2",
              },
              {
                label: "3º",
                value: "MPBE3",
              },
              {
                label: "4º",
                value: "MPBE4",
              },
              {
                label: "5º",
                value: "MPBE5",
              },
              {
                label: "6º",
                value: "MPBE6",
              },
              {
                label: "7º",
                value: "MPBE7",
              },
              {
                label: "8º",
                value: "MPBE8",
              },
              {
                label: "9º",
                value: "MPBE9",
              },
              {
                label: "10º",
                value: "MPBE10",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            title: "Cuña",
          },
          {
            type: "checkBox",
            label: "CUÑA SUPINADORA EXTRINSICA COMPLETA",
            value: "MCSEC",
            name: "cuña_supinadora_extrinsica_completa",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "CUÑA PRONADORA EXTRINSICA COMPLETA",
            value: "MCPEC",
            name: "cuña_pronadora_extrinsica_completa",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "select",
            label: "Grosor extrinseco",
            value: "grosor_extrinseco",
            name: "grosor_extrinseco",
            hideLabel: true,
            unselectable: true,
            placeholder: "Grosor...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "Sin cuña",
                value: "MCGE0",
              },
              {
                label: "1 mm",
                value: "MCGE1",
              },
              {
                label: "2 mm",
                value: "MCGE2",
              },
              {
                label: "3 mm",
                value: "MCGE3",
              },
              {
                label: "4 mm",
                value: "MCGE4",
              },
              {
                label: "5 mm",
                value: "MCGE5",
              },
              {
                label: "6 mm",
                value: "MCGE6",
              },
              {
                label: "7 mm",
                value: "MCGE7",
              },
              {
                label: "8 mm",
                value: "MCGE8",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "cuña pronadora intrinsica mediopié",
            value: "MCPI",
            name: "cuña_pronadora_intrinsica",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkbox",
            label: "Cuña supinadora intrinsica mediopié",
            value: "MCSI",
            name: "cuña_supinadora_intrinsica",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "select",
            label: "Grosor intrinseco",
            value: "grosor_intrinseco",
            name: "grosor_intrinseco",
            hideLabel: true,
            unselectable: true,
            placeholder: "Grosor...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "Sin cuña",
                value: "MCGI0",
              },
              {
                label: "1 mm",
                value: "MCGI1",
              },
              {
                label: "2 mm",
                value: "MCGI2",
              },
              {
                label: "3 mm",
                value: "MCGI3",
              },
              {
                label: "4 mm",
                value: "MCGI4",
              },
              {
                label: "5 mm",
                value: "MCGI5",
              },
              {
                label: "6 mm",
                value: "MCGI6",
              },
              {
                label: "7 mm",
                value: "MCGI7",
              },
              {
                label: "8 mm",
                value: "MCGI8",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "Cuña supinadora extrínseca mediopié",
            value: "MCSEM",
            name: "cuña_supinadora_extrinsica_mediopie",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Cuña pronadora extrínseca mediopié",
            value: "MCPEM",
            name: "cuña_pronadora_extrinsica_mediopie",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "select",
            label: "Grosor medio pié extrinseco",
            value: "grosor_mediopie_extrinseco",
            name: "grosor_mediopie_extrinseco",
            unselectable: true,
            hideLabel: true,
            placeholder: "Grosor...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "1 mm",
                value: "MCGE1",
              },
              {
                label: "2 mm",
                value: "MCGE2",
              },
              {
                label: "3 mm",
                value: "MCGE3",
              },
              {
                label: "4 mm",
                value: "MCGE4",
              },
              {
                label: "5 mm",
                value: "MCGE5",
              },
              {
                label: "6 mm",
                value: "MCGE6",
              },
              {
                label: "7 mm",
                value: "MCGE7",
              },
              {
                label: "8 mm",
                value: "MCGE8",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "Aleta interna",
            value: "MCAI",
            name: "aleta_interna",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkbox",
            label: "Aleta externa",
            value: "MCAE",
            name: "aleta_externa",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "select",
            label: "Tamaño aleta",
            value: "grosor_aleta",
            name: "grosor_aleta",
            hideLabel: true,
            unselectable: true,
            placeholder: "tamaño...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "Sin tamaño",
                value: "MCTA0",
              },
              {
                label: "Pequeña",
                value: "MCTAP",
              },
              {
                label: "Mediana",
                value: "MCTAM",
              },
              {
                label: "Grande",
                value: "MCTAG",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            title: "Descargas",
          },
          {
            type: "select",
            label: "BARRA RETROCAPITAL",
            value: "barra_retrocapital",
            name: "barra_retrocapital",
            hideLabel: true,
            unselectable: true,
            placeholder: "Tamaño...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "Sin barra retrocapital",
                value: "MDBRE0",
              },
              {
                label: "Pequeña",
                value: "MDBREP",
              },
              {
                label: "Mediana",
                value: "MDBREM",
              },
              {
                label: "Grande",
                value: "MDBREG",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "Blanda de latex",
            value: "MDBLR",
            name: "banda_latex_retrocapital",
            addStyle: {
              paddingLeft: "2rem",
            },
          },
          {
            type: "select",
            label: "ALMENDRA",
            value: "almendrita",
            name: "almendrita",
            hideLabel: true,
            unselectable: true,
            placeholder: "Posición...",
            dimensions: {
              label: 1,
              component: 6,
            },
            options: [
              {
                label: "Sin almendra",
                value: "MDA0",
              },
              {
                label: "Lateral",
                value: "MDAL",
              },
              {
                label: "Central",
                value: "MDAC",
              },
              {
                label: "Medial",
                value: "MDAM",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "Blanda de latex",
            value: "MDBLA",
            name: "banda_latex_almendra",
            addStyle: {
              paddingLeft: "2rem",
            },
          },
          {
            type: "select",
            label: "Tamaño almendra",
            hideLabel: true,
            unselectable: true,
            placeholder: "tamaño...",
            dimensions: {
              label: 1,
              component: 6,
            },
            name: "Barra_retrocapital_y_almendrita_grosor",
            options: [
              {
                label: "Sin tamaño",
                value: "MDTA0",
              },
              {
                label: "Pequeña",
                value: "MDTAP",
              },
              {
                label: "Mediana",
                value: "MDTAM",
              },
              {
                label: "Grande",
                value: "MDTAG",
              },
            ],
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
            goFlex: true,
          },
          {
            type: "checkBox",
            label: "BOVEDA RELLENA DE PORON",
            value: "MDBRP",
            name: "bobeda_rellena_de_poron",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "DESCARGA ESTILOIDES",
            value: "MDE",
            name: "descarga_estiloides",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
          {
            type: "checkBox",
            label: "Descarga de estiloides con poron",
            value: "MDEP",
            name: "estiloides_relleno_poron",
            feet: {
              name: "pie",
              value: ["Izq", "Der", "Bi"],
            },
          },
        ],
        shadows: [
          {
            value: "barra_retrocapital",
            png: barraRetrocapital,
            styles: {
              top: -185,
              width: 540,
              left: -13,
            },
          },
          {
            value: "descarga_estiloides",
            png: descargaEstiloides,
            styles: {
              top: -90,
              left: -180,
              width: 550,
            },
          },
          {
            value: "cuña_supinadora_extrinsica_completa",
            png: cunaSupinadoraExtrinsica,
            styles: {
              top: -57,
              left: -95,
              width: 550,
            },
          },
          {
            value: "almendrita",
            png: almendrita,
            styles: {
              top: -115,
              width: 520,
              left: -123,
            },
          },
          {
            value: "bobeda_rellena_de_poron",
            png: bobedaRellenaPoron,
            styles: {
              top: -90,
              left: -110,
              width: 550,
            },
          },
          {
            value: "cuña_pronadora_extrinsica_completa",
            png: cunaPronadoraExtrinsica,
            styles: {
              top: -60,
              left: -167,
              width: 550,
            },
          },
          {
            value: "cuña_supinadora_intrinsica",
            png: cuna_int_larga,
            styles: {
              top: 198,
              width: 69,
              left: 87,
            },
          },
          {
            value: "cuña_pronadora_intrinsica",
            png: cuna_ext_larga,
            styles: {
              top: 248,
              width: 69,
              left: 35,
            },
          },
        ],
      },
    },
  ],
};
