// Dependencies
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import logger from 'redux-logger'

// Reducers
import orders from './reducers/orders';
import app from './reducers/app';

export default createStore(
  combineReducers({
    orders,
    app
  }),applyMiddleware(thunk,logger)
);
