import React, { useState } from "react";
import { toast } from "react-toastify";
import { setFavorite } from "../../../resources/services";
import { FavoriteModal } from "../modals/favoriteModal";


export const FavoriteOrderButton = ({ukey}) => {

  const [showModal, setShowModal] = useState(false);

  const handleAddFavoriteOrder = (favoriteText) => {
    const favoriteName = favoriteText.trim();
    if (favoriteName === "") {
      alert("Por favor, escriba un texto identificativo.");
      return false;
    }
    setFavorite({
      ukey,
      name: favoriteName,
    }).then((response) => {
      if (response.response === 1 && response.created === 1) {
        toast("Se ha creado el favorito correctamente", {
          type: toast.TYPE.SUCCESS,
        });
      } else {
        toast("No se ha podido crear el favorito", {
          type: toast.TYPE.ERROR,
        });
      }
    });
    setShowModal(false)
  };

  return (
    <>
      <i
        className="entypo-star favorite"
        style={{ cursor: "pointer" }}
        onClick={() => setShowModal(true)}
      ></i>
      {showModal && (
        <FavoriteModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onSave={handleAddFavoriteOrder}
        />
      )}
    </>
  );
};
