import React from 'react'
import { Link } from 'react-router-dom'
import {
  ORDER_STATUS,
  ORDER_TYPE,
  USER_ROLES
} from '../../../core/constants/roles'

export const DetailFixOrderButton = ({ ukey, status = '', role }) => {
  const isAdmin = (role) => {
    return role === USER_ROLES.DESIGNER || role === USER_ROLES.ADMIN
  }

  const routesByStatus = {
    [ORDER_STATUS.PROPOSAL]: `/pedido/arreglo/${ukey}`,
    [ORDER_STATUS.DESIGN]: isAdmin(role)
      ? `/pedido/arreglo/${ukey}`
      : `/pedido/${ukey}`
  }

  const showIcon = (status) => {
    const statusPencil =
      status === ORDER_STATUS.PROPOSAL ||
      (status === ORDER_STATUS.DESIGN && isAdmin())
    return statusPencil ? 'entypo-pencil' : 'entypo-eye'
  }
  const route = routesByStatus[status] || `/pedido/${ukey}`

  return (
    <Link to={route} key={`link-${ukey}`}>
      <button
        type="button"
        className="btn btn-default"
        style={{ marginLeft: '10px' }}
      >
        <i className={showIcon(status)}></i>
      </button>
    </Link>
  )
}
