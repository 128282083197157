import React, { Component } from 'react';

// SECTION
import SidebarMenu from './../sidebar-menu/sidebar-menu';
import Header from './../header/header';

class Container extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    }
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onCollapsed = this.onCollapsed.bind(this);
    this.height = window.outerHeight;
  }

  onChangeSection(section) {
    this.section = section;
  }

  onCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }


  render() {
    return (
      <div className={`page-container ${this.state.collapsed ? "sidebar-collapsed" : ""}`}>
        <SidebarMenu onChangeSection={this.onChangeSection} onCollapsed={this.onCollapsed}/>
        <div className="main-content" style={{minHeight: this.height}}>
          <Header />
          <hr />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Container;
