const app = (state = {
  logged: false,
  idRol: 0,
  idUser: '',
  name: '',
  logo: '',
}, action) => {
    switch (action.type) {
      case 'UPDATE_APP':
        return Object.assign({}, state, action.data);
      default:
        return state
    }
  };

  export default app
  