import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import Table from '../../components/table/table'
import { deletePodologist, getPodologist } from './../../resources/services'
import { getPodologistColumn } from './utils/columns'

class Podologists extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }

    getPodologist().then((result) => {
      if (result.response) {
        this.setState({
          data: result.customers
        })
      } else {
        toast('Se ha producido un error al realizar la consulta', {
          type: toast.TYPE.ERROR
        })
      }
    })

    this.deletePodologist = this.deletePodologist.bind(this)

    this.columns = getPodologistColumn(
      this.props.app.idRol,
      this.deletePodologist
    )
  }

  deletePodologist(json) {
    window.confirm(
      `Estás seguro de que deseas eliminar el podólogo ${json.NAME}?`
    ) &&
      deletePodologist({ u: json.UKEY }).then((result) => {
        if (result.response && result.deleted > 0) {
          let { data } = this.state
          data = data.filter((v) => v.UKEY !== json.UKEY)
          this.setState({ data })
          toast('El podólogo se ha borrado con éxito', {
            type: toast.TYPE.SUCCESS
          })
        } else {
          toast('Se ha producido un error al realizar la consulta', {
            type: toast.TYPE.ERROR
          })
        }
      })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb section={[{ title: 'Podólogos', url: 'podologos' }]} />
        <div className="text-right">
          <Link to="/podologo/" className="btn btn-primary">
            <i className="entypo-plus"></i> Añadir Podólogo
          </Link>
        </div>
        <Table
          keyFilter={'NAME'}
          data={this.state.data}
          columns={this.columns}
          title="Podólogos"
          pagination={true}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(Podologists)
