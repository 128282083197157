export const USER_ROLES = {
  ADMIN: '99',
  DESIGNER: '4',
  QA: '3',
  FABRICATION: '2',
  CLIENT: '1'
}

export const ORDER_STATUS = {
  DESIGN: '1',
  FABRICATION: '2',
  QA: '3',
  FINISHED: '4',
  PROPOSAL: '5',
  DELETED: '6',
  SENDED: '7',
  DELIVERED: '8',
  ARREGLO: '9'
}

export const ORDER_TYPE = {
  NEW: '1',
  FIX: '2'
}
