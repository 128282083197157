import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { debounce } from "../core/utils/debounce";

export const useQueryParamsHandler = () => {
  const { search, ...props } = useLocation();
  const history = useHistory();
  const url = useRef(new URL(window.location.href));

  const params = new URLSearchParams(search);
  const searchObj = Object.fromEntries(params);

  const changeQueryParam = (key, value) => {
    url.current.searchParams.set(key, value);
    if (value.length === 0) {
      url.current.searchParams.delete(key);
    }
    replace();
  };

  const switchKeyQueryParam = (key, newKey) => {
    const value = url.current.searchParams.get(key);
    url.current.searchParams.set(newKey, value);
    url.current.searchParams.delete(key);
    replace();
  };

  const replace = debounce(() =>
    history.replace({ search: url.current.search })
  );

  return { search, searchObj, changeQueryParam, switchKeyQueryParam, replace };
};

export const withQueryParamsHandler = (Component) => {
  return function WrappedComponent(props) {
    const queryParamsHandler = useQueryParamsHandler();
    return <Component {...props} queryParamsHandler={queryParamsHandler} />;
  };
};
