import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Breadcrumb extends Component {
  constructor(props) {
    super(props)
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  goBack = () => {
    window.history.back()
  }

  render() {
    const { section } = this.props
    return (
      <ol className="breadcrumb bc-3">
        <li>
          <Link to="/pedidos/">
            <i className="entypo-home"></i>Home
          </Link>
        </li>
        {section.map((v, k) => (
          <li className="active" key={`breadcrumb-${k}`}>
            {k === section.length - 1 ? (
              <strong>{this.capitalize(v.title)}</strong>
            ) : k === section.length - 2 ? ( // elemento anterior
              <a style={{ cursor: 'pointer' }} onClick={this.goBack}>
                {this.capitalize(v.title)}
              </a>
            ) : (
              <Link to={`/${v.url}`}>{this.capitalize(v.title)}</Link>
            )}
          </li>
        ))}
      </ol>
    )
  }
}

export default Breadcrumb
