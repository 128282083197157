import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

// Components
import InputOption from './../../components/input-option/input-option';

// Actions
import { updateOrderAction } from '../../redux/actions/orders';

// Styles
import './canvas-foot.css';

// images
import image from './img/pie-izquierdo.svg';

class CanvasFoot extends Component {

  constructor(props){
    super(props);
    this.state = {
      placeholder: props.placeholder,
      name: props.name,
      type: props.type,
      label: props.label,
      dimensions: props.dimensions,
    }
  }
  
  render() {
    const { properties } = this.props.data.components[0];
    const svgStyles = {
        width: '488px',
        height: '510px',
        transform: 'rotate(13deg)',
        left: '-24px',
        position: 'absolute',
        top: '-80px',
    }
    const svgContainer = {
        margin: '30px auto',
        position: 'relative',
        overflow: 'hidden',
        width: 200,
        height: 420
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-md-8 col-sm-12">
                <InputOption
                    {...properties}
                />
            </div>
            <div className="col-xs-12 col-md-4 col-sm-12 pull-left">
                <div style={svgContainer}>
                    <ReactSVG 
                        src={image}
                        svgStyle={svgStyles}
                    />
                    {properties.shadows.map((v, k) => 
                        <img
                            alt="canvas-foot"
                            src={v.png}
                            style={v.styles}
                            key={`img_canvas_foo_k${k}`}
                            className={`shadows ${
                                this.props.orders[v.value] ? '' : 'hidden'
                            }`}
                        />
                    )}
                </div>
            </div>
        </div>
    );
  }
}

CanvasFoot.propTypes = {
    placeholder: propTypes.string,
    name: propTypes.string,
    type: propTypes.string,
    id: propTypes.string,
    label: propTypes.string,
    dimensions: propTypes.object,
    hideLabel: propTypes.bool,
    group: propTypes.array,
};
  
CanvasFoot.defaultProps = {
    placeholder: 'Escribe un texto',
    name: '',
    type: 'text',
    id: '',
    label: 'Label',
    dimensions: {
        label: 2,
        element: 3,
    },
    hideLabel: false,
    group: [],
};

const mapStateToProps = state => ({
    orders: state.orders,
  });
const mapDispatchToProps = {
    updateOrder: updateOrderAction,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(CanvasFoot);