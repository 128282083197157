import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const DetailArreglo = ({ order }) => {
  return (
    <div>
      <p className="title">Nombre: {order.name}</p>
      <p className="title">Numero de lote: {order.numeroLote}</p>
      <p className="title">Clinica: {order.clinica}</p>
      <p className="title">Material base: {order.material_base}</p>
      <Link to={`/pedido/${order.ukey}`}> Ver detalle del pedido</Link>
    </div>
  )
}
