import React from "react";
import { default as Tooltip } from "react-tooltip";
import { NewDeliveryOrderButton } from "../../../components/new-delivery-order-button";
import { formatDate } from "../../../../../core/utils/date";

export const ChangeDeliveryDateRow = ({
  currentDeliveryDate,
  ukey,
  onChange,
  minimumDate,
  disabled = false,
}) => {
  return (
    <div className="modal-row">
      <div className="fifty">
        Modificar fecha de envío estimada{" "}
        <i
          data-tip
          data-for="tooltip-modify-date"
          className="entypo-info-circled"
        ></i>
        <Tooltip id="tooltip-modify-date" effect="solid" place="top">
          <p>
            Feetsoluter,
            <br />{" "}
            {!disabled
              ? "Recuerda que solo podrás modificarlo una sola vez."
              : "Lo sentimos, la fecha ya ha sido modificada."}
          </p>
        </Tooltip>
      </div>
      <div className="fifty">
        <NewDeliveryOrderButton
          ukey={ukey}
          minimumDate={minimumDate}
          currentDeliveryDate={formatDate(currentDeliveryDate)}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
