import React from "react";
import DatePicker from "react-multi-date-picker";
import { FORMAT_DATE, FORMAT_SERVER_DATE } from '../../../core/constants/constants'

export const ReportModal = ({ show, onClose, onGenerate }) => {
  const maxDate = new Date()

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleOnGenerate = () => {
    onGenerate &&
    onGenerate(startDate.format(FORMAT_SERVER_DATE.toUpperCase()), endDate ? endDate.format(FORMAT_SERVER_DATE.toUpperCase()) : null);
  };
  const handleOnClose = () => {
    onClose && onClose();
  }
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Informe</h4>
          </div>

          <div className="modal-body">
            <form role="form" className="form-horizontal form-groups-bordered" autoComplete="off" style={{height: '8rem'}}>
              <div className="form-group" style={{width: '100%'}}>
                <label htmlFor="field-2" className="col-sm-3 control-label">Fecha de inicio</label>
                  <DatePicker
                    value={startDate}
                    maxDate={maxDate}
                    render={<DateInput/>}
                    format={FORMAT_DATE.toUpperCase()}
                    onChange={(newDate) => setStartDate(newDate)}
                  />
              </div>
              <div className="form-group" style={{width: '100%'}}>
                <label htmlFor="field-2" className="col-sm-3 control-label">Fecha de fin</label>
                  <DatePicker
                    value={endDate}
                    minDate={startDate}
                    maxDate={maxDate}
                    render={<DateInput disabled={!startDate} />}
                    format={FORMAT_DATE.toUpperCase()}
                    onChange={(newDate) => setEndDate(newDate)}
                  />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              disabled={startDate === null}
              onClick={() => handleOnGenerate()}
            >
              Generar
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

const DateInput = ({
  openCalendar,
  value,
  disabled,
  handlerOnChange
}) => {
  return (
    <input
      type={'text'}
      onFocus={() => {
        openCalendar && openCalendar()
      }}
      defaultValue={value}
      onChange={handlerOnChange}
      className={disabled ? 'input-control disabled' : 'input-control'}
      style={{ marginLeft: '1rem', borderRadius: '3px', height: '3rem' }}
    />
  )
}
