import React, { Component } from 'react';
import propTypes from 'prop-types';
import Selector from './../select/select';
import { connect } from 'react-redux';

// Actions
import { updateOrderAction } from '../../redux/actions/orders';

import './selectable-image.css';
import noImage from './images/No_Image_Available.jpg';

class SelectableImage extends Component {
  constructor(props){
    super(props);
    this.state = {
        activeClass: false
    }
  }

  handleClick = () => {
    // can be unSelectable
    const value = this.props.orders[this.props.name] === this.props.value && this.props.unselectable
    ? null
    : this.props.value;

    if(this.props.options.length !== 0) {
        // can be unSelectable
        const firstSelectOption = this.props.orders[this.props.name] === this.props.value && this.props.unselectable
        ? null
        : this.props.options[0].value;
        this.props.updateOrder({
            [this.props.name]: value,
            [`${this.props.name}_selector`]: firstSelectOption,
        });
    } else {        
        this.props.updateOrder({
            [this.props.name]: value,
        });
    }
  }

  getSelector(required){
    const dimensions = {
        label: 12,
        element: 12,
    };
    const name = `${this.props.name}_selector`;
    const value = this.props.orders[name]
    return(<Selector 
        options={this.props.options}
        dimensions={dimensions}
        hideLabel={true}
        placeholder={this.props.placeholder}
        value={value}
        name={name}
        isDisabled={!required}
    />);
  }
  
  render() {
    const imageStyles = {
        height: this.props.imageHeight
    }
    const textCenter = this.props.options.length === 0
    ? 'text-center'
    : '';
    const actived = this.props.orders[this.props.name] === this.props.value;
    return (
        <div className={`col-sm-${this.props.dimension} col-xs-6 ${textCenter} image-selectable text-center`}>
            <div className="image-container">
                {/*<div className="icon-youtube">
                    <i className="entypo-info"></i>
                </div>*/}
                <img 
                    className={`imgW img-thumbnail pointer ${actived && 'active'}`}
                    src={this.props.src}
                    alt={this.props.alt}
                    style={imageStyles}
                    onClick={this.handleClick}
                />
            </div>
            <div className="row">
                <label 
                    htmlFor={this.props.id} 
                    className={`col-sm-12 control-label`}>
                        {this.props.label}
                </label>
                {this.props.options.length !== 0
                    ? this.getSelector(actived)
                    : null
                }
            </div>
        </div>
    );
  }
}

SelectableImage.propTypes = {
    src: propTypes.string,
    alt: propTypes.string,
    options: propTypes.array,
    id: propTypes.string,
    label: propTypes.string,
    dimension: propTypes.number,
    imageHeight: propTypes.string,
    placeholder: propTypes.string,
};
  
SelectableImage.defaultProps = {
    src: noImage,
    options: [],
    alt: 'Selectable image',
    id: '',
    label: 'Label',
    dimension: 3,
    imageHeight: 'auto',
    placeholder: 'Selecciona...'
};

const mapStateToProps = state => ({
    orders: state.orders,
  });
const mapDispatchToProps = {
    updateOrder: updateOrderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectableImage);
