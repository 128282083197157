import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../../components/breadcrumb/breadcrumb'
import {
  getCustomers,
  getPodologist,
  setPodologist
} from './../../../resources/services'

//Action
import { updateAppAction } from './../../../redux/actions/app'

import Select from '../../../components/select/select'
import { isAlphabetical } from '../../../core/utils/validations'
import './detail.css'

class PodologistDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        NAME: '',
        ID_CUSTOMER: ''
      },
      clinicas: [],
      showModal: false,
      error: false,
      buttonDisabled: false
    }
    this.ukey = props.match.params.ukey

    if (this.ukey) {
      getPodologist(this.ukey).then((result) => {
        if (result.response) {
          const data = result.customers[0]
          this.setState({ data })
        } else {
          toast('No se han podido recuperar los datos.', {
            type: toast.TYPE.ERROR
          })
        }
      })
    }

    if (this.props.app.idRol === '99') {
      getCustomers().then((result) => {
        this.setState({
          clinicas: result.customers.map((data) => ({
            value: data.ID,
            label: data.CLINIC_NAME
          }))
        })
      })
    }

    this.save = this.save.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  handleSelectChange(input) {
    const { data } = this.state
    data[input.name] = input.value
    this.setState({ data })
  }

  handleInputChange(event) {
    const target = event.target ? event.target : event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const { data } = this.state
    data[name] = value
    this.setState({ data })
  }

  save() {
    const { data } = this.state

    if (!String(data.NAME).trim().length) {
      toast('Rellene todos los campos.', { type: toast.TYPE.ERROR })
      return false
    }

    // Nuevo podologist
    if (!data.UKEY) {
      //email
      if (!isAlphabetical(data.NAME)) {
        toast('El Nombre solo puede contener caracteres alfabeticos', {
          type: toast.TYPE.ERROR
        })
        return false
      }
      data.UKEY = 'NEW'
    }

    setPodologist(data).then((v) => {
      if (v.response && v.update) {
        toast('Datos guardados correctamente.', { type: toast.TYPE.SUCCESS })
        if (this.state.data.UKEY === 'NEW') {
          this.setState({
            data: {
              NAME: '',
              CLINIC_NAME: ''
            }
          })
        }
      } else {
        toast('Error al guardar los datos.', { type: toast.TYPE.ERROR })
      }
    })
  }

  render() {
    const { data, error, buttonDisabled } = this.state

    const { app } = this.props
    console.log(error)
    if (error) {
      return (
        <Redirect
          to={{
            pathname: '/404/'
          }}
        />
      )
    }

    return (
      <Fragment>
        <Breadcrumb
          section={[
            { title: 'Podólogos', url: 'podologos' },
            { title: 'Detalle del podologo' }
          ]}
        />
        <div
          className={`modal fade ${this.state.showModal ? 'in' : ''}`}
          style={{ display: this.state.showModal ? 'block' : 'none' }}
        ></div>
        <div className="profile-env">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="panel-title">Datos del Podologo</div>
            </div>
            <div className="panel-body">
              <form
                role="form"
                className="form-horizontal form-groups-bordered"
              >
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-1" className="col-sm-3 control-label">
                    Nombre <span className="error">*</span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="NAME"
                      className="form-control"
                      id="field-1"
                      placeholder=""
                      value={data.NAME}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                {app.idRol === '99' ? (
                  <Fragment>
                    <div className="form-group" style={{ width: '100%' }}>
                      <label
                        htmlFor="field-10"
                        className="col-sm-3 control-label"
                      >
                        Clinica
                      </label>
                      <div className="col-sm-8">
                        <Select
                          name="ID_CUSTOMER"
                          options={this.state.clinicas}
                          virtualized={true}
                          value={this.state.data.ID_CUSTOMER}
                          style={{ height: '112px', width: '100%' }}
                          hideLabel={true}
                          onChange={this.handleSelectChange}
                          notSaveInOrder
                        />
                      </div>
                    </div>
                  </Fragment>
                ) : null}

                <button
                  onClick={() => this.props.history.goBack()}
                  type="button"
                  className="btn btn-primary pull-left"
                  style={{ marginTop: '15px' }}
                >
                  <i className="entypo-left-open"></i> Atrás
                </button>
                {app.idRol === '99' || app.idRol === '1' ? (
                  <button
                    type="button"
                    className="btn btn-green pull-right"
                    style={{ marginTop: '15px' }}
                    onClick={this.save}
                    disabled={buttonDisabled}
                  >
                    Guardar <i className="entypo-check"></i>
                  </button>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

const mapDispatchToProps = {
  updateApp: updateAppAction
}

export default connect(mapStateToProps, mapDispatchToProps)(PodologistDetail)
