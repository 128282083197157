import React from 'react'
import { ORDER_STATUS, USER_ROLES } from '../../../../core/constants/roles'
import { DeleteOrderButton } from '../../components/delete-order-button'
import { DetailFixOrderButton } from '../../components/detail-fix-order-button'
import { DownloadOrderButton } from '../../components/download-order-button'
import { ShowOrderModalButton } from '../../components/show-order-modal-button'

export const getFixOrderTableCommands = (row, idRol, setState) => {
  // status 5 = propuesta
  // status 1 = diseño
  const isPossibleChangeDateToSend = (orderStatus) => {
    return (
      orderStatus === ORDER_STATUS.DESIGN ||
      orderStatus === ORDER_STATUS.QA ||
      orderStatus === ORDER_STATUS.FABRICATION
    )
  }
  const currentStatus = row.STATUS
  const patientName = `${row.NOMBRE_PACIENTE} ${row.APELLIDOS_PACIENTE}`
  const idSeries = row.ID_SERIES

  if (idRol === USER_ROLES.ADMIN || idRol === USER_ROLES.DESIGNER) {
    return [
      <ShowOrderModalButton
        key={`show-order-modal-button-${row.UKEY}`}
        onClick={() =>
          setState({
            showModal: true,
            rowSelected: row
          })
        }
      />,
      <DownloadOrderButton
        ukey={row.UKEY}
        patientName={patientName}
        idSeries={idSeries}
        key={`download-order-button-${row.UKEY}`}
      />
    ]
  } else {
    switch (row.STATUS) {
      case ORDER_STATUS.PROPOSAL:
        return (
          <>
            <DetailFixOrderButton
              key={`detail-fix-order-button-${row.UKEY}`}
              role={idRol}
              ukey={row.UKEY}
              status={currentStatus}
            />
            <DeleteOrderButton
              key={`delete-order-button-${row.UKEY}`}
              ukey={row.UKEY}
              onDeleteOrder={(data) => setState({ data })}
            />
          </>
        )
      default:
        return (
          <>
            {idRol === USER_ROLES.CLIENT &&
              isPossibleChangeDateToSend(row.STATUS) && (
                <ShowOrderModalButton
                  key={`show-order-modal-button-${row.UKEY}`}
                  id={row.ID}
                  onClick={() =>
                    setState({
                      showClientModal: true,
                      rowSelected: row
                    })
                  }
                />
              )}
            {
              <DetailFixOrderButton
                key={`detail-fix-order-button-${row.UKEY}`}
                role={idRol}
                ukey={row.UKEY}
                status={currentStatus}
              />
            }
            {currentStatus === ORDER_STATUS.PROPOSAL && (
              <DeleteOrderButton
                key={`delete-order-button-${row.UKEY}`}
                ukey={row.UKEY}
                onDeleteOrder={(data) => setState({ data })}
              />
            )}
          </>
        )
    }
  }
}
