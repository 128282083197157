import { PRICE_DECIMAL, PRICE_IVA } from '../constants/constants'

export const calculatePriceWithIva = (
  price,
  priceDiscount,
  iva = PRICE_IVA
) => {
  const priceToDiscount = priceDiscount ? price * (priceDiscount / 100) : 0
  const priceDiscounted = Math.round((price - priceToDiscount) * 100) / 100
  const priceIva = parseFloat(
    (priceDiscounted * iva).toFixed(PRICE_DECIMAL)
  ).toFixed(2)
  return priceIva
}
