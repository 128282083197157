import React from 'react'
import { List } from 'react-virtualized'
import 'react-virtualized/styles.css'

export const MenuList = (props) => {
  const rows = props.children
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div key={key} style={{ ...style, width: '100%' }}>
      {rows[index]}
    </div>
  )

  return (
    <List
      style={{ width: '100%' }}
      width={700}
      height={220}
      rowHeight={30}
      rowCount={rows.length || 0}
      rowRenderer={rowRenderer}
    />
  )
}
