import React, { Component, Fragment } from 'react'
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import Breadcrumb from "../../../components/breadcrumb/breadcrumb"
import Select from "../../../components/select/select"
import { getCustomers, generateManualInvoice } from "../../../resources/services"
import { updateAppAction } from "../../../redux/actions/app"

class FacturaManualDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: {
        ID_CUSTOMER: '',
        LINES: []
      },
      numLineas: 1,
      clinicas: [],
      showModal: false,
      error: false,
      buttonDisabled: false,
    }

    this.isRefund = !!props.match.params.isRefund
    console.log(this.isRefund, props.match.params)

    getCustomers().then((result) => {
      this.setState({
        clinicas: result.customers.map((data) => ({
          value: data.ID,
          label: data.CLINIC_NAME
        }))
      })
    })

    this.save = this.save.bind(this)
    this.handleCustomerChange = this.handleCustomerChange.bind(this)
    this.handleLineChange = this.handleLineChange.bind(this)
    this.nuevaLinea = this.nuevaLinea.bind(this)
    this.eliminaLinea = this.eliminaLinea.bind(this)
  }

  nuevaLinea() {
    const { numLineas } = this.state
    this.setState({ numLineas: numLineas + 1 })
  }

  eliminaLinea(index) {
    const { numLineas, data } = this.state
    data.LINES.splice(index, 1)
    this.setState({ data, numLineas: numLineas - 1 })
  }

  handleCustomerChange(input) {
    const { data } = this.state
    data.ID_CUSTOMER = input.value
    this.setState({ data })
  }

  handleLineChange(event, lineIndex) {
    const target = event.target ? event.target : event
    const value = target.value
    const name = target.name
    const { data } = this.state
    if (!data.LINES[lineIndex]) data.LINES[lineIndex] = {}
    data.LINES[lineIndex][name] = value
    this.setState({ data })
  }

  save() {
    const { data } = this.state
    data.IS_REFUND = this.isRefund ? "1" : "0"

    if (!String(data.ID_CUSTOMER).trim().length) {
      toast('Debe seleccionar un cliente..', { type: toast.TYPE.ERROR })
      return false
    }

    if (data.LINES.length === 0) {
      toast('Debe introducir almenos una línea.', { type: toast.TYPE.ERROR })
      return false
    }


    for (let li = 0; li < data.LINES.length; li++) {
      const line = data.LINES[li]
      if (!String(line.DESCRIPTION).trim().length) {
        toast('Todas las líneas deben incluir una descripción.', { type: toast.TYPE.ERROR })
        return false;
      }
      if (!line.VALUE) {
        toast('Todas las líneas deben incluir una valor.', { type: toast.TYPE.ERROR })
        return false;
      }
      if (!line.IVA) {
        toast('Todas las líneas deben informar del IVA a aplicar.', { type: toast.TYPE.ERROR })
        return false;
      }
    }

    generateManualInvoice(data).then((v) => {
      if (v.response && v.response === 1) {
        toast(`${this.isRefund ? "Abono generado": "Factura generada"} correctamente.`, { type: toast.TYPE.SUCCESS })
        this.setState({
          data: {
            ID_CUSTOMER: '',
            LINES: []
          },
          numLineas: 1
        })
      } else {
        toast(`Error al generar ${this.isRefund ? "el abono" : "la factura"}.`, { type: toast.TYPE.ERROR })
      }
    })
  }

  render() {
    const { numLineas, data, error, buttonDisabled } = this.state

    if (error) {
      return (
        <Redirect
          to={{
            pathname: '/404/'
          }}
        />
      )
    }

    return (
      <Fragment>
        <Breadcrumb
          section={[
            { title: 'Facturas', url: 'facturas' },
            { title: (this.isRefund ?  "Nuevo abono" : "Nueva factura") }
          ]}
        />
        <div
          className={`modal fade ${this.state.showModal ? 'in' : ''}`}
          style={{ display: this.state.showModal ? 'block' : 'none' }}
        ></div>
        <div className="profile-env">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="panel-title">Datos {this.isRefund ? "del abono" : "de la factura"}</div>
            </div>
            <div className="panel-body">
              <form
                role="form"
                className="form-horizontal form-groups-bordered"
                style={{margin: "auto", width: "85%"}}
              >
                <div className="form-group" style={{ width: '100%' }}>
                  <label
                    htmlFor="cliente"
                    className="col-sm-2 control-label"
                  >
                    Cliente
                  </label>
                  <div className="col-sm-10">
                    <Select
                      id="cliente"
                      name="ID_CUSTOMER"
                      options={this.state.clinicas}
                      virtualized={true}
                      value={this.state.data.ID_CUSTOMER}
                      style={{ height: '112px', width: '100%' }}
                      hideLabel={true}
                      onChange={this.handleCustomerChange}
                      notSaveInOrder
                    />
                  </div>
                </div>

                {[...Array(numLineas).keys()].map(lineIndex => {
                  return (
                    <div className="form-group" style={{width: '100%'}}>
                      <label htmlFor="description" className="col-sm-2 control-label">
                        Descripción
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="DESCRIPTION"
                          className="form-control"
                          id="description"
                          placeholder="Descripción"
                          maxLength={250}
                          value={data.LINES[lineIndex] ? data.LINES[lineIndex].DESCRIPTION : ""}
                          onChange={(e) => this.handleLineChange(e, lineIndex)}
                        />
                      </div>

                      <label htmlFor="value" className="col-sm-2 control-label">
                        Subtotal
                      </label>
                      <div className="col-sm-3">
                        <input
                          type="number"
                          name="VALUE"
                          className="form-control"
                          id="value"
                          min="0"
                          value={data.LINES[lineIndex] ? data.LINES[lineIndex].VALUE : 0}
                          onChange={(e) => this.handleLineChange(e, lineIndex)}
                        />
                      </div>

                      <label htmlFor="iva" className="col-sm-2 control-label">
                        I.V.A.
                      </label>
                      <div className="col-sm-3">
                        <select
                          name="IVA"
                          className="form-control"
                          id="iva"
                          placeholder=""
                          value={data.LINES[lineIndex] ? data.LINES[lineIndex].IVA : "0"}
                          onChange={(e) => this.handleLineChange(e, lineIndex)}
                        >
                          <option value="0">Selecciona una opción</option>
                          <option value="0.1">10%</option>
                          <option value="0.21">21%</option>
                        </select>
                      </div>
                      <div className="col-sm-2">
                        <button
                          onClick={() => this.eliminaLinea(lineIndex)}
                          type="button"
                          className="btn btn-red pull-right"
                        >
                          <i className="entypo-trash"></i>
                        </button>
                      </div>
                    </div>
                  )
                })}
                <div className="form-group col-sm-12 text-center" style={{ width: '100%' }}>
                  <button
                    onClick={this.nuevaLinea}
                    type="button"
                    className="btn btn-green"
                    style={{ marginTop: '15px', marginBottom: '30px' }}
                  >
                    <i className="entypo-plus"></i> Nueva línea
                  </button>
                </div>
                <button
                  onClick={() => this.props.history.goBack()}
                  type="button"
                  className="btn btn-primary pull-left"
                  style={{ marginTop: '15px' }}
                >
                  <i className="entypo-left-open"></i> Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-green pull-right"
                  style={{ marginTop: '15px' }}
                  onClick={this.save}
                  disabled={buttonDisabled}
                >
                  Crear <i className="entypo-check"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

const mapDispatchToProps = {
  updateApp: updateAppAction
}

export default connect(mapStateToProps, mapDispatchToProps)(FacturaManualDetail)