import React from "react";
import { ORDER_TYPE } from "../../../core/constants/roles";
import { formatDate } from "../../../core/utils/date";
import { ChangeStatusOrderButton } from "../components/change-status-order-button";
import { DeleteOrderButton } from "../components/delete-order-button";
import { DetailFixOrderButton } from "../components/detail-fix-order-button";
import { DetailOrderButton } from "../components/detail-order-button";
import { DownloadOrderButton } from "../components/download-order-button";
import { DuplicateOrderButton } from "../components/duplicate-order-button";
import { ChangeDeliveryDateRow } from "./rows/changeDeliveryDate/changeDeliveryDate";

export const AdminModal = ({
  idRol,
  show,
  statusOptions,
  onClose,
  onChangeStatus,
  onDeleteOrder,
  onNewDeliveryDate,
  data,
}) => {
  const currentStatus = data.STATUS;
  const currentUkey = data.UKEY;
  const currentOrderType = data.ORDER_TYPE;
  const currentIdSeries = data.ID_SERIES;

  const currentDeliveryDate = data.DELIVERY_DATE;

  const [deliveryDateModified, setDeliveryDateModified] = React.useState(
    data.DELIVERY_DATE_MODIFIED === "1"
  );

  const handleSetNewDeliveryDate = (newDeliveryDate) => {
    setDeliveryDateModified(true);
    onNewDeliveryDate && onNewDeliveryDate(newDeliveryDate);
  };

  const DetailOrderButtonElm =
    currentOrderType === ORDER_TYPE.NEW
      ? DetailOrderButton
      : DetailFixOrderButton;

  const patientName = `${data.NOMBRE_PACIENTE} ${data.APELLIDOS_PACIENTE}`;
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Opciones del pedido</h4>
          </div>
          <div className="modal-body">
            <ChangeDeliveryDateRow
              ukey={currentUkey}
              minimumDate={formatDate(new Date())}
              currentDeliveryDate={currentDeliveryDate}
              onChange={handleSetNewDeliveryDate}
            />
            <div>
              <div className="fifty">
                {currentStatus === "1" || currentStatus === "5"
                  ? "Editar pedido:"
                  : "Ver pedido:"}
              </div>
              <DetailOrderButtonElm
                role={idRol}
                ukey={currentUkey}
                status={currentStatus}
              ></DetailOrderButtonElm>
            </div>
            <div>
              <div className="fifty">Descargar etiqueta: </div>
              <DownloadOrderButton
                ukey={currentUkey}
                patientName={patientName}
                idSeries={currentIdSeries}
                type="etiqueta"
              />
            </div>
            <div>
              <div className="fifty">Descargar etiqueta de envio: </div>
              <DownloadOrderButton
                ukey={currentUkey}
                patientName={patientName}
                idSeries={currentIdSeries}
                type="etiqueta"
                sendLabel={true}
              />
            </div>
            {currentOrderType === ORDER_TYPE.NEW && (
              <div>
                <div className="fifty">Duplicar pedido:</div>
                <DuplicateOrderButton ukey={currentUkey} />
              </div>
            )}

            {idRol === "99" || idRol === "4" ? (
              <div>
                <div className="fifty">Cambiar el estado del pedido:</div>
                <div className="selectChangeStatus">
                  <ChangeStatusOrderButton
                    ukey={currentUkey}
                    status={currentStatus}
                    statusOptions={statusOptions}
                    onChangeStatus={() => onChangeStatus && onChangeStatus()}
                  ></ChangeStatusOrderButton>
                </div>
              </div>
            ) : null}
            <div>
              <div className="fifty">Eliminar pedido:</div>
              <DeleteOrderButton
                ukey={currentUkey}
                onDelete={() => onDeleteOrder && onDeleteOrder()}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={
                () => onClose && onClose()
                //   this.setState({ showModal: false, currentUkey: 0 })
              }
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
